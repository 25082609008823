<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-for="(group) in allData" :key="group.key" style="margin-top: 30px; margin-bottom:20px;">
      <h3>{{group.key}}</h3>

      <table class="hiaTable" style="margin-top: 20px;">
        <thead>
          <tr>
            <th>Coder</th>
            <th>Total Records</th>
            <th>Total Codes</th>
            <th>ICD-10 CM Codes</th>
            <th>CPTs with Reimb.</th>
            <th>CPTs without Reimb.</th>
            <th>Modifier Codes</th>
            <th>Financial Impact</th>
          </tr>
        </thead>
        <tr v-for="item in group.data" :key="item.key">
          <td class="textAlignLeft"> {{item.coder}} </td>
          <td> {{item.totalRecords}} </td>
          <td> {{formatPercentage(item.totalCodes)}} </td>
          <td> {{formatPercentage(item.cmCodes)}} </td>
          <td> {{formatPercentage(item.cptWithReimb)}} </td>
          <td> {{formatPercentage(item.cptWithoutReimb)}} </td>
          <td> {{formatPercentage(item.modCodes)}} </td>
          <td :class="`${item.ascFinancialImpact < 0 ? 'negative' : ''}`"> {{item.ascFinancialImpact}}</td>
        </tr>
        <tr class="strong emphasis">
          <td class="textAlignLeft">Total</td>
          <td>{{getSummaries(group.data)[1]}}</td>
          <td>{{getSummaries(group.data)[2]}}</td>
          <td>{{getSummaries(group.data)[3]}}</td>
          <td>{{getSummaries(group.data)[4]}}</td>
          <td>{{getSummaries(group.data)[5]}}</td>
          <td>{{getSummaries(group.data)[6]}}</td>
          <td :class="`${getSummaries(group.data)[7] < 0 ? 'negative' : ''}`">{{getSummaries(group.data)[7]}}</td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'

  export default {
    name: 'OP_ByCoderPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'codeTypes'],
    components: {
    },
    data: function () {
      return {
        allData: [],
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/opByCoder`, payload)
          this.allData = response.data.data
          this.tableData = response.data.data
        } catch (err) {
          this.allData = []
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'coder' },
            { property: 'totalRecords' },
            { property: 'totalCodes' },
            { property: 'cmCodes' },
            { property: 'cptWithReimb' },
            { property: 'cptWithoutReimb' },
            { property: 'modCodes' },
            { property: 'ascFinancialImpact' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }
</style>