<template>
  <div>
    <div style="margin-top: 50px;" v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
    <div v-if="!loading">
      <div v-for="worksheet in worksheets" :key="worksheet.patientNumber" class="page-break" style="margin-bottom: 50px;">
        <h3>Outpatient Review Worksheet</h3>
        <OutpatientWorksheet :worksheet="worksheet" :optionalParameters="optionalParameters"></OutpatientWorksheet>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { format, parseISO } from 'date-fns'
  import OutpatientWorksheet from '@/views/reviews/outpatient/worksheets/OutpatientWorksheet.vue'
  import axios from '@/utilities/axios-global'

  export default {
    name: 'Worksheets',
    props: ['ascTableIds', 'codeTypes', 'optionalParameters'],
    data() {
      return {
        loading: true,
        format: format,
        parseISO: parseISO,
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups', 'clients']
      }
    },
    components: {
      OutpatientWorksheet
    },
    methods: {
      async getWorksheets() {
        this.loading = true
        const ids = {
          ids: this.ascTableIds,
          codeTypes: this.codeTypes
        }
        if (this.outpatientReviewer() || this.ambulatoryReviewer()) {
          const response = await axios.post(this.baseApiUrl + '/reviews/outpatient/worksheets?changesOnly=true', ids)
          this.worksheets = response.data
        }

        if (!this.outpatientReviewer() && !this.ambulatoryReviewer() && this.coder()) {
          const response = await axios.post(this.baseApiUrl + '/coder/outpatient/worksheets?changesOnly=true', ids)
          this.worksheets = response.data
        }

        this.loading = false
      }
    },
    mounted: async function () {
      await this.getWorksheets()
    },
    watch: {
      showDrgWorksheets: function () {
        this.getWorksheets()
      },
      showFyiWorksheets: function () {
        this.getWorksheets()
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    }
  }
</script>

<style scoped>
  @media print {
    .page-break {
      display: block;
      page-break-after: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>