<template>
  <div class="table-wrapper">
    <!-- {{ item.patTypeDesc }} -->
    <table class="hiaTable">
      <thead>
        <tr>
          <th style="width: 190px;">
          </th>
          <th>
            Total DRGs
          </th>
          <th>
            DRGs w/o Query Recomm.
          </th>
          <th>
            Total Codes
          </th>
          <th>
            ICD-10 CM Codes
          </th>
          <th>
            ICD-10 PCS Codes
          </th>
          <th>
            Patient Status Codes
          </th>
          <!-- <th>
              Patient Status Fin. Impact
            </th> -->
          <th>
            Point of Origin Codes
          </th>
          <th>
            POA Indicators
          </th>
        </tr>
      </thead>
      <tr>
        <td class="strong textAlignLeft">
          Total Reviewed
        </td>
        <td>
          {{item.totalRecords}}
        </td>
        <td>
          {{item.totalRecords}}
        </td>
        <td>
          {{item.totalCodesReviewed}}
        </td>
        <td>
          {{item.dxTotalReviewed}}
        </td>
        <td>
          {{item.pxTotalReviewed}}
        </td>
        <td>
          {{item.patStat}}
        </td>
        <!-- <td>
              Pat Stat Fin
            </td> -->
        <td>
          {{item.poo}}
        </td>
        <td>
          {{item.poaTotalReviewed}}
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          Total Recommendations
        </td>
        <td>
          {{item.drgChanges}}
        </td>
        <td>
          {{item.asIsDRGChanges}}
        </td>
        <td>
          {{item.totalCodesRecommended}}
        </td>
        <td>
          {{item.dxTotalRec}}
        </td>
        <td>
          {{item.pxTotalRec}}
        </td>
        <td>
          {{item.patStatChanges}}
        </td>
        <!-- <td>
              Pat Stat Fin
            </td> -->
        <td>
          {{item.pooChanges}}
        </td>
        <td>
          {{item.poaRecommendations}}
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          No Recommendations
        </td>
        <td>
          {{item.drgNoRec}}
        </td>
        <td>
          {{item.drgWOQueryNoRec}}
        </td>
        <td>
          {{item.totalCodesNoRec}}
        </td>
        <td>
          {{item.dxNoRec}}
        </td>
        <td>
          {{item.pxNoRec}}
        </td>
        <td>
          {{item.patStatNoRec}}
        </td>
        <!-- <td>
              {{item.patStatFinNoRec}}
            </td> -->
        <td>
          {{item.pooNoRec}}
        </td>
        <td>
          {{item.poaNoRecommendations}}
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          Additions
        </td>
        <td>
        </td>
        <td>
        </td>
        <td>
          {{item.totalCodesAdditions}}
        </td>
        <td>
          {{item.dxAdditions}}
        </td>
        <td>
          {{item.pxAdditions}}
        </td>
        <td>
        </td>
        <!-- <td>
            </td> -->
        <td>
        </td>
        <td>
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          Deletions
        </td>
        <td>
        </td>
        <td>
        </td>
        <td>
          {{item.totalCodesDeletions}}
        </td>
        <td>
          {{item.dxDeletions}}
        </td>
        <td>
          {{item.pxDeletions}}
        </td>
        <td>

        </td>
        <td>

        </td>
        <!-- <td>

            </td> -->
        <td>
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          Revisions
        </td>
        <td>
          {{item.drgChanges}}
        </td>
        <td>
          {{item.asIsDRGChanges}}
        </td>
        <td>
          {{item.totalCodesRev}}
        </td>
        <td>
          {{item.dxRevisions}}
        </td>
        <td>
          {{item.pxRevisions}}
        </td>
        <td>
          {{item.patStatChanges}}
        </td>
        <!-- <td>
              Pat Stat Fin
            </td> -->
        <td>
          {{item.pooChanges}}
        </td>
        <td>
          {{item.poaRecommendations}}
        </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft">
          Accuracy Rate
        </td>
        <td>
          {{formatPercentage(item.totalDRGs)}}
        </td>
        <td>
          {{formatPercentage(item.totalDRGNoQuery)}}
        </td>
        <td>
          {{formatPercentage(item.totalCodes)}}
        </td>
        <td>
          {{formatPercentage(item.cmCodes)}}
        </td>
        <td>
          {{formatPercentage(item.pcsCodes)}}
        </td>
        <td>
          {{formatPercentage(item.patStatCodes)}}
        </td>
        <!-- <td>
              Pat Stat Fin
            </td> -->
        <td>
          {{formatPercentage(item.pooCodes)}}
        </td>
        <td>
          {{formatPercentage(item.poaCodes)}}
        </td>
      </tr>
    </table>

    <table style="margin-top: 20px;" class="hiaTable">
      <thead>
        <tr>
          <th>
            Total Financial Changes
          </th>
          <th>
            Potential Increases
          </th>
          <th>
            # Increases
          </th>
          <th>
            Potential Decreases
          </th>
          <th>
            # Decreases
          </th>
          <th>
            Net Dollar Impact
          </th>
        </tr>
      </thead>
      <tr>
        <td>
          {{!isNaN(item.reimbIncRecords) && !isNaN(item.reimbDecRecords) ? item.reimbIncRecords + item.reimbDecRecords : null}}
        </td>
        <td>
          {{formatMoney(item.reimbInc)}}
        </td>
        <td>
          {{item.reimbIncRecords}}
        </td>
        <td>
          {{formatMoney(item.reimbDec)}}
        </td>
        <td>
          {{item.reimbDecRecords}}
        </td>
        <td :class="`${item.reimbDiff < 0 ? 'negative' : ''}` ">{{ moneyFormatter(item.reimbDiff) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  export default {
    name: 'IPBasicSummary',
    props: ['item'],
    mixins: [money, dashboardHelper],
    data: function () {
      return {
      }
    },
    methods: {}
  }
</script>

<style scoped>
  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>