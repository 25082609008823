<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <el-row v-if="!loading" :gutter="20">
      <el-col :span="12">
        <div>
          <table class="hiaTable">
            <thead>
              <tr>
                <th>Total Records Reviewed</th>
                <th>Total Records with Findings</th>
                <th>Total Records Without Findings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ recordTotals.totalRecords }}</td>
                <td>{{ recordTotals.recordsWithFindings }}</td>
                <td>{{ recordTotals.recordsWithoutFindings }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <table class="hiaTable" style="margin-top: 20px;">
          <thead>
            <tr>
              <th>Description</th>
              <th>Frequency</th>
            </tr>
          </thead>
          <tr v-for="item in tableData" :key="item.description">
            <td class="textAlignLeft">{{ item.description }}</td>
            <td>{{ item.numberOfCodes }}</td>
          </tr>
          <tr class="strong emphasis">
            <td class="textAlignLeft">Total</td>
            <td>{{ summary[1] }}</td>
          </tr>
        </table>
      </el-col>
      <el-col :span="10">
        <div>
          <barChart style="margin: 57px 0px 0px 0px;" :data="tableData" :nameValue="'description'" :yValue="'numberOfCodes'" :yTitle="'Frequency'"></barChart>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import barChart from '../highcharts/barChart'

  export default {
    name: 'ASC_FindingSummaryCombinedPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'codeTypes'],
    components: {
      barChart
    },
    data: function () {
      return {
        tableData: [],
        recordTotals: {},
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/opCombinedFindingSummary`, payload)
          this.tableData = response.data.largeStat.data
          this.recordTotals = response.data.recordTotals
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getCodeTypes() {
        const codeTypes = [...new Set(this.tableData.map(x => x.codeDesc))]
        return codeTypes
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      summary: function () {
        const param = {
          columns: [{ property: 'description' }, { property: 'numberOfCodes' }],
          data: this.tableData
        }
        return this.getSummary(param)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }

  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>