<template>
  <div>
    <div>
      <el-row :gutter="10">
        <el-col :span="4" style="border-right: 1px solid #eee;">
          <el-row>
            <el-col :span="24">
              <el-button-group style="width: 100%;">
                <el-button style="width: 33%;" round class="hiaButton" :disabled="ipReportDataLoading" plain @click.stop="resetAll()"><i class="el-icon-refresh"></i> Clear </el-button>
                <el-button style="width: 33%;" title="View Queries" class="hiaButton" :disabled="ipReportDataLoading" plain @click.stop="showQueriesDialog = !showQueriesDialog"><i class="el-icon-view"></i> Queries</el-button>
                <el-button style="width: 33%;" round v-if="!Object.keys(ipActiveQuery).length || readOnly()" title="Save New Query" class="hiaButton" ref="ipSaveQuery" :disabled="ipReportDataLoading || readOnly()" plain @click="showSaveQueryPopover(false, 'ipSaveQuery')"><i class="el-icon-finished"></i> Save</el-button>
                <el-dropdown ref="ipQueryCommandButton" v-if="Object.keys(ipActiveQuery).length && !readOnly()" :disabled="ipReportDataLoading" class="queryCommandButton" split-button plain @click="showSaveQueryPopover(false, 'ipQueryCommandButton')" @command="ipHandleQueryCommand">
                  <i class="el-icon-finished"></i> Save
                  <el-dropdown-menu :disabled="ipReportDataLoading" slot="dropdown">
                    <el-dropdown-item icon="el-icon-edit" command="edit">Edit</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-share" command="share">Share</el-dropdown-item>
                    <el-dropdown-item command="saveAs"><i class="el-icon-finished"></i>Save As</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-close" command="delete">Delete</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-button-group>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0px 0px 0px;">
            <el-col :span="24">
              <h4 class="headerWithBorder">Inpatient Filters</h4>
              <el-form class="sortableColumnsForm">
                <el-row>
                  <div v-for="(filter, index) in inpatientReport.inpatientData.filters.filter(x => !x.hidden)" :key="index">
                    <el-col :span="16">
                      <el-form-item>
                        <input :disabled="ipReportDataLoading" :key="filter.column" type="checkbox" v-model="filter.active" @click="selectedFilter(filter)" /><span style="font-size: 11px; line-height: 12px;">{{ filter.columnLabel }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <i :class="getTagClass(filter.columnType)"></i><span class="tagSpan">{{ filter.columnType }}</span>
                    </el-col>
                  </div>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="20">
          <el-row style="margin: 5px 0px 0px 0px;">
            <el-col :span="6">
              <span>{{ Object.keys(ipActiveQuery).length ? ipActiveQuery.title : 'New Query' }}</span>
            </el-col>
            <el-col :span="12">
              <el-input size="mini" placeholder="filter all..." suffix-icon="el-icon-search" v-model="globalSearch" @input="runGlobalSearch()"> </el-input>
            </el-col>
            <el-col :span="6"> </el-col>
          </el-row>
          <el-row class="filterRow">
            <el-col :span="24">
              <i title="Filter Section" class="mdi mdi-filter-variant filterIcon"></i>
              <div class="filterBlock" v-for="filter in inpatientReport.inpatientData.filters.filter(f => f.active && !f.hidden)" :key="filter.column">
                <el-button-group :class="`query_${filter.column} filter_${filter.columnType}`" :ref="`query_${filter.column}`">
                  <el-button :disabled="ipReportDataLoading" type="primary" :title="`${filterBlockTitle(filter)}`" size="mini" round plain @click="toggleFilterPopover(filter)">{{ filterBlockLabel(filter) }}</el-button>
                  <el-button :disabled="ipReportDataLoading" type="primary" title="remove" class="removeFilter" size="mini" icon="el-icon-close" round plain @click="removeFilter(filter)"></el-button>
                </el-button-group>
              </div>
            </el-col>
          </el-row>
          <el-row class="dataRow">
            <el-col :span="24">
              <el-button v-if="ipActiveTab === 'ip'" class="hiaButton csvDownloadButton" :disabled="ipReportDataLoading" size="mini" plain round @click="generateReportCSVLink()"><i class="mdi mdi-file-download-outline"></i> Export CSV</el-button>
              <el-tabs class="dataTabs" v-model="ipActiveTab">
                <el-tab-pane label="Dashboard" name="dashboard">
                  <dashboards :drgTableIds="inpatientReport.inpatientData.drgTableIds" type="ip" :isReportSection="true"></dashboards>
                </el-tab-pane>
                <el-tab-pane label="Records" name="ip">
                  <inpatientListFilter ref="ipFilterList"></inpatientListFilter>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="poppers">
      <el-popover ref="filterPopover" placement="bottom" width="400" :title="currentFilter.columnLabel" v-model="filterPopoverVisible">
        <div>
          <template v-if="!['date', 'tag'].some(s => s === currentFilter.columnType)">
            <el-input style="margin: 0px 0px 5px 0px;" size="mini" placeholder="filter..." suffix-icon="el-icon-search" v-model="currentFilter.filterText" @input="runTextFilter(currentFilter)" clearable> </el-input>
            <el-select v-if="currentFilter.includeUniqueValues" v-model="currentFilter.filterValues" filterable multiple collapse-tags placeholder="select multiple..." @visible-change="runFilterValues($event, currentFilter)">
              <el-option v-for="item in currentFilter.uniqueValues" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </template>
          <template v-if="currentFilter.columnType === 'date'">
            <el-row>
              <el-col :span="2">
                <input type="radio" value="filterText" v-model="currentFilter.dateCriteria" />
              </el-col>
              <el-col :span="22">
                <el-input :disabled="currentFilter.dateCriteria !== 'filterText'" style="margin: 0px 0px 5px 0px;" size="mini" placeholder="filter..." suffix-icon="el-icon-search" v-model="currentFilter.filterText" clearable> </el-input>
              </el-col>
            </el-row>
            <el-row style="margin: 0px 0px 0px 0px;">
              <el-col :span="2">
                <input type="radio" value="withinDate" v-model="currentFilter.dateCriteria" />
              </el-col>
              <el-col :span="22">
                <small>Within the last</small>
                <input :disabled="currentFilter.dateCriteria !== 'withinDate'" style="width: 50px; margin: 0px 5px 0px 5px;" type="number" min="0" v-model="currentFilter.withinDate" />
                <el-select style="width: 80px;" :disabled="currentFilter.dateCriteria !== 'withinDate'" v-model="currentFilter.dateOption" collapse-tags placeholder="select...">
                  <el-option v-for="item in dateFilterOptions" :key="item.key" :label="item.key" :value="item.value"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin: 5px 0px 0px 0px;">
              <el-col :span="2">
                <input type="radio" value="dateBetween" v-model="currentFilter.dateCriteria" />
              </el-col>
              <el-col :span="22">
                <small>between</small>
                <el-date-picker style="float: right; width: 85% !important;" :disabled="currentFilter.dateCriteria !== 'dateBetween'" class="hiaRangePicker" size="mini" format="MM/dd/yyyy" value-format="MM/dd/yyyy" v-model="currentFilter.dateBetween" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" :picker-options="dateRangePickerConfig" :clearable="false"> </el-date-picker>
              </el-col>
            </el-row>
          </template>

          <template v-if="currentFilter.columnType === 'tag'">
            <el-row>
              <el-col :span="2">
                <input type="radio" value="withinTag" v-model="currentFilter.tagCriteria" />
              </el-col>
              <el-col :span="22">
                <small>contains</small>
                <el-select style="float: right; width: 85% !important;" :disabled="currentFilter.tagCriteria !== 'withinTag'" v-model="currentFilter.filterValues" filterable multiple collapse-tags placeholder="select multiple...">
                  <el-option v-for="item in currentFilter.uniqueValues" :key="item.key" :label="item.value" :value="item.key"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin: 5px 0px 0px 0px;">
              <el-col :span="2">
                <input type="radio" value="equalsTag" v-model="currentFilter.tagCriteria" />
              </el-col>
              <el-col :span="22">
                <small>equals</small>
                <el-select style="float: right; width: 85% !important;" :disabled="currentFilter.tagCriteria !== 'equalsTag'" v-model="currentFilter.filterValues" filterable multiple collapse-tags placeholder="select multiple...">
                  <el-option v-for="item in currentFilter.uniqueValues" :key="item.key" :label="item.value" :value="item.key"> </el-option>
                </el-select>
              </el-col>
            </el-row>
          </template>
        </div>
        <el-button-group style="float: right; margin: 10px 0px 0px 0px;">
          <el-button class="hiaButton" type="text" :disabled="ipReportDataLoading" size="mini" @click="clearFilter(currentFilter)" plain round>Clear</el-button>
          <el-button class="hiaButton" :disabled="ipReportDataLoading" size="mini" @click="toggleFilterPopover(currentFilter)" plain round>Close</el-button>
          <el-button class="hiaButton" v-if="['date', 'tag'].some(s => s === currentFilter.columnType)" :disabled="ipReportDataLoading" size="mini" @click="applyFilter(currentFilter)" plain round>Apply</el-button>
        </el-button-group>
      </el-popover>

      <el-popover ref="updateQueryPopper" placement="bottom" width="210" v-model="updateQueryPopOverVisible" @after-enter="handleHidePopOver('updateQueryPopper')">
        <el-form size="mini" ref="updateQueryform" label-position="top" label-width="120px" :rules="addQueryRules" :model="currentQuery">
          <el-form-item size="mini" label="Query Title" prop="title" id="addTitle">
            <el-input size="mini" :maxlength="50" type="text" id="title" v-model="currentQuery.title"> </el-input>
          </el-form-item>
          <el-form-item size="mini" label="Public" prop="isPublic" id="addPublic">
            <input type="checkbox" v-model="currentQuery.isPublic" />
          </el-form-item>
        </el-form>
        <br />
        <el-button-group style="float: right; margin: 10px 0px 0px 0px;">
          <el-button class="hiaButton" type="text" size="mini" @click="updateQueryPopOverVisible = false" plain round>Cancel</el-button>
          <el-button class="hiaButton" size="mini" @click="updateQueryMeta(currentQuery)" plain round>Confirm</el-button>
        </el-button-group>
      </el-popover>

      <el-popover ref="saveQueryPopover" trigger="click" placement="bottom" v-model="addQueryPopOverVisible" @after-enter="handleHidePopOver('saveQueryPopover')">
        <el-form size="mini" ref="queryForm" label-position="top" label-width="120px" :rules="addQueryRules" :model="addQueryForm">
          <el-form-item size="mini" label="Title" prop="title" id="addTitle">
            <el-input size="mini" :maxlength="50" type="text" id="title" v-model="addQueryForm.title"> </el-input>
          </el-form-item>
          <el-form-item size="mini" label="Make Public" prop="isPublic" id="addPublic">
            <input type="checkbox" v-model="addQueryForm.isPublic" />
          </el-form-item>
        </el-form>

        <el-button-group style="float: right; margin: 10px 0px 0px 0px;">
          <el-button class="hiaButton" type="text" size="mini" @click="cancelFilter()" plain round>Cancel</el-button>
          <el-button class="hiaButton" size="mini" @click="saveQuery()" plain round>Confirm</el-button>
        </el-button-group>
      </el-popover>

      <el-popover ref="shareQueryPopper" placement="bottom" width="210" v-model="shareQueryPopOverVisible" @after-enter="handleHidePopOver('shareQueryPopper')">
        <p>Share Query with</p>
        <el-select v-model="shareQueryUsers" multiple collapse-tags placeholder="select multiple...">
          <el-option v-for="user in usersList.filter(f => f.userId !== currentTenantUserId)" :key="user.userId" :label="user.fullName" :value="user.userId"> </el-option>
        </el-select>
        <el-button-group style="float: right; margin: 10px 0px 0px 0px;">
          <el-button class="hiaButton" type="text" size="mini" @click="shareQueryPopOverVisible = false" plain round>Cancel</el-button>
          <el-button class="hiaButton" size="mini" @click="shareQuery(currentQuery)" plain round>confirm</el-button>
        </el-button-group>
      </el-popover>

      <el-popover ref="deleteQueryPopper" placement="bottom" width="210" v-model="deleteQueryPopOverVisible" @after-enter="handleHidePopOver('deleteQueryPopper')">
        <p>Delete this Query?</p>
        <el-button-group style="float: right; margin: 10px 0px 0px 0px;">
          <el-button class="hiaButton" type="text" size="mini" @click="deleteQueryPopOverVisible = false" plain round>Cancel</el-button>
          <el-button class="hiaButton" size="mini" @click="deleteQuery(currentQuery)" plain round>confirm</el-button>
        </el-button-group>
      </el-popover>
    </div>
    <div class="dialogs">
      <el-dialog :visible.sync="showQueriesDialog" :title="'Saved Queries'" width="60%" :close-on-click-modal="true" :show-close="true" append-to-body>
        <v-client-table class="hiaTable" v-loading="false" element-loading-spinner="atom-audit-loader" ref="reportQueriesGrid" id="reportQueriesGrid" :data="reportQueries.filter(x => x.type === 'ip')" :columns="reportQueriesColumns" :options="reportQueriesOptions">
          <div slot="filter__isPublic">
            <input type="checkbox" @change="$refs.reportQueriesGrid.setFilter({ isPublic: $event.target.checked })" />
          </div>
          <template slot="title" slot-scope="props">
            <a href="#" title="activate this query" @click.prevent="activateQuery(props.row)">{{ props.row.title }}</a>
          </template>
          <template slot="isPublic" slot-scope="props">
            <i :style="props.row.isPublic ? 'color: #13ce66; font-size: 16px; font-weight: bold;' : 'color: #F56C6C; font-size: 16px; font-weight: bold;'" :class="props.row.isPublic ? 'el-icon-check' : ''"></i>
          </template>
          <template slot="queryActions" slot-scope="props">
            <div style="float: right;">
              <el-button :disabled="disableQuery(props.row) || readOnly()" :ref="`updateQuery_${props.row.reportQueryId}`" @click.native.prevent="toggleUpdateQueryPopover(props.row, `updateQuery_${props.row.reportQueryId}`)" plain round size="mini" icon="el-icon-edit" title="Edit Title" circle></el-button>
              <el-button :disabled="disableQuery(props.row) || readOnly()" :ref="`saveAsQuery_${props.row.reportQueryId}`" @click.native.prevent="showSaveQueryPopover(true, `saveAsQuery_${props.row.reportQueryId}`)" plain round size="mini" icon="el-icon-finished" title="Save As" circle></el-button>
              <el-button :disabled="disableQuery(props.row) || readOnly()" :ref="`shareQuery_${props.row.reportQueryId}`" @click.native.prevent="toggleShareQueryPopover(props.row, `shareQuery_${props.row.reportQueryId}`)" plain round size="mini" icon="el-icon-share" title="Share" circle></el-button>
              <el-button :disabled="disableQuery(props.row) || readOnly()" :ref="`deleteQuery_${props.row.reportQueryId}`" @click.native.prevent="toggleDeleteQueryPopover(props.row, `deleteQuery_${props.row.reportQueryId}`)" plain round size="mini" icon="el-icon-close" title="Delete" circle></el-button>
            </div>
          </template>
        </v-client-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import inpatientListFilter from '@/views/reports/inpatientListFilter'
  // import proListFilter from '@/views/reports/proListFilter'
  import dashboards from './dashboards'
  import debounce from 'debounce'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'ipReportSection',
    mixins: [dateHelper, hiaTable, HiaAuthorization],
    data() {
      return {
        ipActiveQuery: {},
        saveAsFlag: false,
        showQueriesDialog: false,
        deleteQueryPopOverVisible: false,
        shareQueryPopOverVisible: false,
        updateQueryPopOverVisible: false,
        shareQueryUsers: [],
        defaultDateRange: [],
        isCollapsed: true,
        addQueryForm: {
          title: '',
          isPublic: false
        },
        addQueryRules: {
          title: [{ required: true, message: 'Please Set Title' }]
        },
        addQueryPopOverVisible: false,
        loading: false,
        ipFilterCollapse: 'ip',
        ipActiveTab: 'dashboard',
        addFilterVisible: false,
        filterPopoverVisible: false,
        currentFilter: {},
        globalSearch: null,
        currentQuery: {},
        dateFilterOptions: [
          { key: 'days', value: 'days' },
          { key: 'weeks', value: 'weeks' },
          { key: 'months', value: 'months' },
          { key: 'years', value: 'years' }
        ],
        reportQueriesColumns: ['title', 'type', 'isPublic', 'addedFormat', 'queryActions'],
        reportQueriesOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'clientName',
            ascending: true
          },
          headings: {
            title: 'Title',
            type: 'Type',
            isPublic: 'Public',
            addedFormat: 'Created',
            queryActions: ''
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('userAdmin/', ['GET_USERS']),
      ...mapActions('reports/', ['POST_REPORTCSV']),
      ...mapActions('reports/reportQueries/', ['GET_REPORTQUERIES', 'POST_REPORTQUERIES', 'PUT_REPORTQUERIES', 'PUT_SHAREREPORTQUERIES', 'DELETE_REPORTQUERIES']),
      ...mapMutations('reports/reportQueries/', ['UPDATE_REPORTQUERY_ACTIVE']),
      ...mapMutations('reports/', ['SET_REPORTS_INPATIENT_FILTER', 'SET_REPORTS_INPATIENT_REPORTFILTER', 'SET_REPORTS_INPATIENT_GLOBALSEARCH', 'SET_REPORTS_DEFAULTCOMPLETED', 'SET_REPORTS_DEFAULTDATERANGE', 'RESET_REPORTS_INPATIENT_FILTER', 'PUSH_REPORTS_FILTERS']),
      async initialize() {
        // const end = new Date()
        // const start = new Date(new Date().getFullYear(), 0, 1)
        // this.defaultDateRange = [this.formatDate(start), this.formatDate(end)]
        // this.SET_REPORTS_DEFAULTDATERANGE(this.defaultDateRange)
        // this.SET_REPORTS_DEFAULTCOMPLETED()
        // await this.GET_USERS()
        // await this.GET_REPORTQUERIES()
      },
      toggleFilterPopover(filter) {
        this.currentFilter = filter
        const popper = this.$refs.filterPopover
        if (popper) {
          popper.referenceElm = this.$refs[`query_${filter.column}`][0].$el
          this.filterPopoverVisible = !this.filterPopoverVisible
        }
      },
      toggleAddFilterPopover() {
        this.filterPopoverVisible = false
        const popper = this.$refs.sortableColumns
        if (popper) {
          popper.referenceElm = this.$refs.addFilterButton.$el
        }
        this.addFilterVisible = true
      },
      selectedFilter(filter) {
        this.addFilterVisible = false
        setTimeout(() => {
          if (filter.active) {
            this.toggleFilterPopover(filter)
          } else {
            this.clearFilter(filter)
          }
        }, 100)
      },
      removeFilter(filter) {
        filter.active = false
        filter.filterText = null
        filter.filterValues = []
        filter.dateBetween = []
        filter.moreThanDate = 0
        filter.withinDate = 0
        filter.dateOption = 'days'
        filter.dateCriteria = null
        this.SET_REPORTS_INPATIENT_FILTER(filter)
        this.filterPopoverVisible = false
        this.refreshData(filter.reportType)
      },
      clearFilter(filter) {
        this.filterPopoverVisible = false
        filter.filterText = null
        filter.filterValues = []
        filter.dateBetween = []
        filter.moreThanDate = 0
        filter.withinDate = 0
        filter.dateOption = 'days'
        filter.dateCriteria = null
        this.SET_REPORTS_INPATIENT_FILTER(filter)
        this.refreshData('ip')
      },
      runTextFilter: debounce(function(filter) {
        this.applyFilter(filter)
      }, 400),
      applyFilter(filter) {
        this.SET_REPORTS_INPATIENT_FILTER(filter)
        this.refreshData(filter.reportType)
        // this.filterPopoverVisible = false
      },
      runFilterValues(val, filter) {
        if (!val) {
          this.applyFilter(filter)
        }
      },
      runGlobalSearch: debounce(function(filter) {
        this.SET_REPORTS_INPATIENT_GLOBALSEARCH(this.globalSearch)
        this.refreshData('ip')
      }, 400),
      filterBlockLabel(filter) {
        let displayList = [filter.columnLabel]
        if (filter.columnType !== 'date') {
          if (filter.filterText !== null && filter.filterText !== '') {
            const columnLabelIndex = displayList.indexOf(filter.columnLabel)
            displayList.push(filter.filterText)
            displayList.splice(columnLabelIndex, 1)
          }
          if (filter.filterValues !== null) {
            if (filter.filterValues.length) {
              const columnLabelIndex = displayList.indexOf(filter.columnLabel)
              displayList = displayList.concat(filter.filterValues)
              displayList.splice(columnLabelIndex, 1)
            }
          }
        }

        return displayList.join(',')
      },
      filterBlockTitle(filter) {
        let displayList = [filter.columnLabel]
        if (filter.columnType === 'date') {
          if (filter.dateCriteria === 'filterText' && filter.filterText !== null && filter.filterText !== '') {
            displayList.push(filter.filterText)
          }
          if (filter.dateCriteria === 'withinDate') {
            displayList.push(`Within the last ${filter.withinDate} ${filter.dateOption}`)
          }
          if (filter.dateCriteria === 'dateBetween' && filter.dateBetween.length === 2) {
            displayList.push(`between ${filter.dateBetween[0]} and ${filter.dateBetween[1]}`)
          }
        } else {
          if (filter.filterText !== null && filter.filterText !== '') {
            displayList.push(filter.filterText)
          }
          if (filter.filterValues) {
            displayList = displayList.concat(filter.filterValues)
          }
        }
        return displayList.join('\n')
      },
      resetAll() {
        this.ipActiveQuery = {}
        this.RESET_REPORTS_INPATIENT_FILTER()
        const activeReportQuery = this.reportQueries.filter(f => f.active === true)
        if (activeReportQuery) {
          const currentFilterPayload = {
            type: 'ip',
            reportQuery: activeReportQuery,
            active: false
          }
          this.UPDATE_REPORTQUERY_ACTIVE(currentFilterPayload)
        }
        this.refreshData('ip')
      },
      refreshData(reportType) {
        this.$refs.ipFilterList.$refs.reviewListGrid.setPage(1)
      },
      async showSaveQueryPopover(saveAs, ref) {
        const activeQuery = this.reportQueries.find(f => f.active === true && f.type === 'ip')
        console.log(activeQuery)
        this.saveAsFlag = saveAs
        if (activeQuery && !saveAs) {
          await this.updateQuery(activeQuery)
          this.ipActiveQuery = this.calcActiveQuery('ip')
        } else {
          const popper = this.$refs.saveQueryPopover
          if (popper) {
            popper.referenceElm = this.$refs[ref].$el
          }
          this.addQueryPopOverVisible = true
        }
      },
      toggleUpdateQueryPopover(currentQuery, ref) {
        this.currentQuery = JSON.parse(JSON.stringify(currentQuery))
        const popper = this.$refs.updateQueryPopper
        if (popper) {
          popper.referenceElm = this.$refs[ref].$el
        }
        this.updateQueryPopOverVisible = true
      },
      toggleShareQueryPopover(currentQuery, ref) {
        this.shareQueryUsers = []
        this.currentQuery = currentQuery
        const popper = this.$refs.shareQueryPopper
        if (popper) {
          popper.referenceElm = this.$refs[ref].$el
        }
        this.shareQueryPopOverVisible = true
      },
      toggleDeleteQueryPopover(currentQuery, ref) {
        this.currentQuery = currentQuery
        const popper = this.$refs.deleteQueryPopper
        if (popper) {
          popper.referenceElm = this.$refs[ref].$el
        }
        this.deleteQueryPopOverVisible = true
      },
      saveQuery() {
        this.$refs.queryForm.validate(async valid => {
          if (valid) {
            try {
              // clone; remove unique values; set local copy for future saves
              let cloneUserFilter = {}
              cloneUserFilter = JSON.parse(JSON.stringify(this.inpatientReport.inpatientData.filters.filter(f => f.active)))

              for (let i = 0; i < cloneUserFilter.length; i++) {
                cloneUserFilter[i].UniqueValues = []
              }
              const payload = {
                title: this.addQueryForm.title,
                isPublic: this.addQueryForm.isPublic,
                filter: JSON.stringify(cloneUserFilter),
                type: 'ip',
                userId: this.currentTenantUserId
              }
              const response = await this.POST_REPORTQUERIES(payload)
              const currentQueryPayload = {
                type: 'ip',
                reportQuery: response,
                active: true
              }
              this.UPDATE_REPORTQUERY_ACTIVE(currentQueryPayload)
              this.addQueryPopOverVisible = false
              this.clearForm()
              this.ipActiveQuery = this.calcActiveQuery('ip')
              this.$message({
                message: 'Query Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Query Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async updateQuery(activeQuery) {
        try {
          let cloneUserFilter = {}
          cloneUserFilter = JSON.parse(JSON.stringify(this.inpatientReport.inpatientData.filters.filter(f => f.active)))

          for (let i = 0; i < cloneUserFilter.length; i++) {
            cloneUserFilter[i].UniqueValues = []
          }
          activeQuery.filter = JSON.stringify(cloneUserFilter)
          await this.PUT_REPORTQUERIES(activeQuery)
          this.$message({
            message: 'Query Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Query Updated Error',
            type: 'error'
          })
        }
      },
      activateQuery(query, node, treenode) {
        switch (query.type) {
          case 'ip': {
            const currentQueryPayload = {
              type: 'ip',
              reportQuery: query,
              active: true
            }
            this.UPDATE_REPORTQUERY_ACTIVE(currentQueryPayload)
            const parseQuery = JSON.parse(query.filter)
            this.SET_REPORTS_INPATIENT_REPORTFILTER(parseQuery)
            this.ipActiveQuery = this.calcActiveQuery('ip')
            this.refreshData('ip')
            break
          }
          case 'op': {
            this.$emit('activateQuery', query)
            break
          }
          case 'pro': {
            this.$emit('activateQuery', query)
            break
          }
        }
        this.showQueriesDialog = false
      },
      cancelFilter() {
        this.addQueryPopOverVisible = false
        this.clearForm()
      },
      clearForm() {
        this.addQueryForm.title = ''
        this.addQueryForm.isPublic = false
      },
      viewToggleQueries() {
        this.isCollapsed = !this.isCollapsed
      },
      disableQuery(query) {
        return !!(query.isPublic && this.currentTenantUserId !== query.userId)
      },
      async updateQueryMeta(query) {
        try {
          await this.PUT_REPORTQUERIES(query)
          this.updateQueryPopOverVisible = false
          this.ipActiveQuery = this.calcActiveQuery('ip')
          this.$message({
            message: 'Query Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.updateQueryPopOverVisible = false
          this.$message({
            message: 'Query Updated Error',
            type: 'error'
          })
        }
      },
      async deleteQuery(query) {
        try {
          await this.DELETE_REPORTQUERIES(query)
          if (query.active) {
            this.resetAll()
          }
          this.deleteQueryPopOverVisible = false
          this.$message({
            message: 'Query Deleted Successful',
            type: 'success'
          })
        } catch (err) {
          this.deleteQueryPopOverVisible = false
          this.$message({
            message: 'Query Deleted Error',
            type: 'error'
          })
        }
      },
      async shareQuery(query) {
        try {
          const payload = {
            reportQuery: query,
            userIds: this.shareQueryUsers
          }
          await this.PUT_SHAREREPORTQUERIES(payload)
          this.shareQueryUsers = []
          this.shareQueryPopOverVisible = false
          this.$message({
            message: 'Query Shared Successful',
            type: 'success'
          })
        } catch (err) {
          this.shareQueryUsers = []
          this.shareQueryPopOverVisible = false
          this.$message({
            message: 'Query Shared Error',
            type: 'error'
          })
        }
      },
      ipHandleQueryCommand(command) {
        switch (true) {
          case command === 'edit':
            this.toggleUpdateQueryPopover(this.ipActiveQuery, 'ipQueryCommandButton')
            break
          case command === 'share':
            this.toggleShareQueryPopover(this.ipActiveQuery, 'ipQueryCommandButton')
            break
          case command === 'saveAs':
            this.showSaveQueryPopover(true, 'ipQueryCommandButton')
            break
          case command === 'delete':
            this.toggleDeleteQueryPopover(this.ipActiveQuery, 'ipQueryCommandButton')
            break
        }
      },
      async generateReportCSVLink() {
        const payload = {
          docIds: this.inpatientReport.inpatientData.drgTableIds,
          type: 'ip'
        }
        const response = await this.POST_REPORTCSV(payload)
        this.generateDownloadLink(response)
      },
      generateDownloadLink(href) {
        const link = document.createElement('a')
        link.setAttribute('style', 'display: hidden;')
        link.setAttribute('download', 'download')
        link.id = 'hiddenLink'
        link.href = `${this.baseApiUrl}${href}`
        document.getElementsByTagName('body')[0].appendChild(link)
        document.getElementById('hiddenLink').click()
        // remove link
        const timerId = setInterval(() => {
          document.getElementById('hiddenLink').remove()
          clearInterval(timerId)
        }, 500)
      },
      calcActiveQuery(type) {
        const activeQuery = this.reportQueries.find(f => f.type === 'ip' && f.active === true) ? this.reportQueries.find(f => f.type === 'ip' && f.active === true) : {}
        return activeQuery
      },
      getTagClass(columnType) {
        let style = ''
        switch (columnType) {
          case 'text':
            style = 'mdi mdi-format-letter-case tagIcon magentaIcon'
            break
          case 'number':
            style = 'mdi mdi-numeric tagIcon blueIcon'
            break
          case 'date':
            style = 'mdi mdi-clock-time-three-outline tagIcon greenIcon'
            break
          case 'tag':
            style = 'mdi mdi-tag-outline tagIcon goldIcon'
            break
        }
        return style
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      }
    },
    watch: {},
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('reports/', ['inpatientReport', 'ipReportDataLoading']),
      ...mapState('reports/reportQueries/', ['reportQueries', 'usersList']),
      ...mapState('globals/', ['dateRangePickerConfig']),
      ...mapState('user/', ['user', 'currentTenantUserId', 'baseApiUrl']),
      queryData() {
        if (this.reportQueries.filter(f => f.userId === this.currentTenantUserId && f.isPublic === false).length) {
          const queryData = [
            {
              id: 1,
              title: 'Personal',
              children: this.reportQueries.filter(f => f.userId === this.currentTenantUserId && f.isPublic === false)
            },
            {
              id: 2,
              title: 'Public',
              children: this.reportQueries.filter(f => f.isPublic === true)
            }
          ]
          return queryData
        }
        return []
      }
    },
    created: function() {
      this.initialize()
    },
    components: {
      inpatientListFilter,
      dashboards
    }
  }
</script>

<style scoped>
  .queryRow {
    margin: 0px 0px 10px 0px;
  }

  .filterRow {
    margin: 10px 0px 0px 0px;
    min-height: 25px;
  }

  .divider div {
    font-size: 12px;
  }

  .dataTabs {
    margin: 0px 0px 0px 0px;
  }

  .filterBlock {
    font-size: 11px;
    float: left;
    margin: 5px 0px 0px 3px;
  }

  .filterBlock button {
    padding: 3px 15px;
    width: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filterBlock button.removeFilter {
    width: auto;
  }

  .filterBlock .removeFilter {
    padding: 3px 5px;
  }

  .saveQuery {
    float: left;
    margin: 0px 0px 0px 5px;
  }

  .saveQuery button {
    padding: 3px 15px;
  }

  .saveQuery button span {
    font-size: 11px;
  }

  .hiaRangePicker {
    width: 300px;
  }

  .activeFilter {
    background: #ecf5ff;
  }

  .filterMenu.el-menu--collapse {
    width: 50px;
  }

  .filterMenu .el-menu-item,
  .filterMenu >>> .el-submenu__title {
    padding: 0px 0px 0px 0px;
    padding-left: 0px !important;
  }

  .filterMenu >>> .el-menu--inline .el-menu-item {
    padding-left: 10px !important;
  }

  >>> .filterActionButtons {
  }

  >>> .filterActionButtons button i {
    font-size: 12px;
  }

  >>> .filterActionButtons .el-popover__reference-wrapper {
    line-height: 0px;
    float: left;
    position: relative;
  }

  .queryTree >>> .el-tree-node .el-tree-node__content:hover {
    background-color: transparent;
  }

  .queryTree >>> .el-tree-node__children .el-tree-node__content:hover {
    background-color: #f5f7fa !important;
    border-radius: 20px;
  }

  .queryTree >>> .el-tree-node__content {
    height: unset;
  }

  .queryTree >>> .el-tree-node.is-current > .el-tree-node__content {
    background-color: transparent;
  }

  .queryTree >>> .el-tree-node__children > .is-current > .el-tree-node__content {
    background-color: #f5f7fa !important;
    border-radius: 20px;
  }

  /* beta styles */
  >>> .reportCard .el-card__body {
    padding: 5px;
  }

  >>> .queryCommandButton {
    width: 33%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  >>> .queryCommandButton button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  >>> .queryCommandButton button:hover {
    background-color: #ffffff;
    border-color: #005695;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  >>> .queryCommandButton .el-button--mini {
    width: 70%;
  }

  >>> .queryCommandButton .el-dropdown__caret-button {
    width: 30%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  >>> .queryCommandButton .el-dropdown__caret-button:focus {
    background-color: #ffffff;
    border-color: #005695;
  }

  >>> .queryActions {
    float: right;
  }

  >>> .queryActions button {
    padding: 7px 7px 0px 7px;
  }

  >>> .queryActions button span {
    font-size: 18px;
  }

  .reportDivider {
    margin: 14px 0;
  }

  .reportDivider .el-divider__text {
    font-size: 11px;
    padding: 0 10px;
    font-weight: 400;
  }

  .dataRow {
    margin: 10px 0px 0px 0px;
  }

  .csvDownloadButton {
    float: right;
    z-index: 1;
    position: relative;
    margin: 5px 0px 0px 0px;
  }
</style>

<style></style>
