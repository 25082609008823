<template>
  <div id="inpatientAuditReport" v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-if="!loading">
      <div class="options print-hidden report-header-row">
        <el-row>
          <el-col :span="22">
            <el-row>
              <el-col :span="6">
                <el-button type="text" @click="selectAll">Select All</el-button>
                <el-button type="text" @click="unselectAll">Unselect All</el-button>
              </el-col>
            </el-row>

            <el-row class="hidden-sm">
              <el-col :span="6" v-for="(chunk, i) in chunkDashboards(4)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"
                    ><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label
                  >
                </div>
              </el-col>
            </el-row>

            <el-row class="hidden-lg">
              <el-col :span="8" v-for="(chunk, i) in chunkDashboards(3)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"
                    ><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label
                  >
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2">
            <el-button icon="mdi mdi-file-download-outline" class="pull-right print-hidden hiaButton" round plain style="margin-top: 30px; margin-bottom: 0px; z-index: 100;" size="mini" @click="saveReport">Download </el-button>
          </el-col>
        </el-row>

        <el-row v-if="showDrgWorksheets || showFyiWorksheets">
          <div style="display: inline-block; padding: 15px 10px 15px 10px; background-color: #f3f2f1a9; margin-top: 15px; margin-bottom: 10px; border-radius: 5px; ">
            <h5 style="margin-bottom: 8px; margin-top: 0px;">Optional Parameters</h5>
            <input type="checkbox" v-model="optionalParameters.hideWorksheetCoderResponses" />
            <label class="toc">Hide Worksheet Coder Responses</label>
          </div>
        </el-row>
      </div>
      <div class="mainReport" style="margin-top: 20px;">
        <TenantReportHeader></TenantReportHeader>
        <h1 contenteditable style="margin-top: 20px;" @input="setTitle($event)">{{ title ? `Inpatient Coding Audit Results for ${title}` : `Inpatient Coding Audit Results` }}<i style="font-size: 16px; margin-left: 5px; cursor: pointer; color: #b4bccc" class="mdi mdi-pencil print-hidden"></i></h1>

        <div v-for="(dashboard, i) in dashboards.filter(f => getShowFlag(f.showFlag))" :key="dashboard.name">
          <div>
            <h2 :class="i > 0 ? 'page-break' : ''" :id="dashboard.scrollTo">{{ dashboard.name }}</h2>

            <template v-if="dashboard.component !== 'IPWorksheetsPrintable'">
              <component ref="parentComponent" :is="dashboard.component" :drgTableIds="drgTableIds"> </component>
            </template>

            <template v-if="dashboard.component === 'IPWorksheetsPrintable' && dashboard.name === 'DRG Change Worksheets'">
              <component ref="parentComponent" :is="dashboard.component" :drgTableIds="drgTableIds" :showDrgWorksheets="true" :showFyiWorksheets="false" :optionalParameters="optionalParameters"> </component>
            </template>

            <template v-if="dashboard.component === 'IPWorksheetsPrintable' && dashboard.name === 'FYI Worksheets'">
              <component ref="parentComponent" :is="dashboard.component" :drgTableIds="drgTableIds" :showDrgWorksheets="false" :showFyiWorksheets="true" :optionalParameters="optionalParameters"> </component>
            </template>
          </div>
        </div>
        <span class="watermark">Powered by Atom Audit</span>
      </div>
    </div>
    <a id="hiddenAnchor" style="display: none;"></a>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import IPBasicSummaryPrintable from './printable/IP_BasicSummaryPrintable.vue'
  import IPWorksheetFindingsPrintable from './printable/IP_FindingSummaryWorksheetPrintable.vue'
  import IPCombinedFindingsPrintable from './printable/IP_FindingSummaryCombinedPrintable.vue'
  import IPDxFindingsPrintable from './printable/IP_FindingSummaryDxPrintable.vue'
  import IPPxFindingsPrintable from './printable/IP_FindingSummaryPxPrintable.vue'
  import IPByFacilityPrintable from './printable/IP_ByFacilityPrintable.vue'
  import IPByServiceLinePrintable from './printable/IP_ByServiceLinePrintable.vue'
  import IPByProjectPrintable from './printable/IP_ByProjectPrintable.vue'
  import IPByCoderPrintable from './printable/IP_ByCoderPrintable.vue'
  import IPByCDIStaffPrintable from './printable/IP_ByCDIStaffPrintable.vue'
  import IPByMdcPrintable from './printable/IP_ByMdcPrintable.vue'
  import IPByDrgPrintable from './printable/IP_ByDrgPrintable.vue'
  import IPDxPrintable from './printable/IP_DxSummaryPrintable.vue'
  import IPHCCPrintable from './printable/IP_HCCSummaryPrintable.vue'
  import IPHCCV28Printable from './printable/IP_HCCSummaryV28Printable.vue'
  import IPPxPrintable from './printable/IP_PxSummaryPrintable.vue'
  import IPByReasonPrintable from './printable/IP_ByReasonPrintable.vue'
  import IPPsiSummaryPrintable from './printable/IP_PsiSummaryPrintable.vue'
  import IPQmSummaryPrintable from './printable/IP_QmSummaryPrintable.vue'
  import IPWorksheetsPrintable from './printable/IP_WorksheetsPrintable.vue'
  import IPRebillLogPrintable from './printable/IP_RebillLogPrintable.vue'
  import TenantReportHeader from '@/views/reports/printable/TenantReportHeader.vue'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import IPDocAssessmentPrintable from './printable/IP_DocAssessmentPrintable.vue'
  import IPTrending from './printable/IP_TrendingPrintable.vue'

  export default {
    name: 'InpatientAuditReport',
    components: {
      IPBasicSummaryPrintable,
      IPRebillLogPrintable,
      IPWorksheetFindingsPrintable,
      IPCombinedFindingsPrintable,
      IPDxFindingsPrintable,
      IPPxFindingsPrintable,
      IPByFacilityPrintable,
      IPByProjectPrintable,
      IPByServiceLinePrintable,
      IPByCoderPrintable,
      IPByCDIStaffPrintable,
      IPByDrgPrintable,
      IPDxPrintable,
      IPHCCPrintable,
      IPHCCV28Printable,
      IPPxPrintable,
      IPByReasonPrintable,
      IPPsiSummaryPrintable,
      IPQmSummaryPrintable,
      IPWorksheetsPrintable,
      IPByMdcPrintable,
      TenantReportHeader,
      IPDocAssessmentPrintable,
      IPTrending
    },
    mixins: [HiaAuthorization],
    props: [],
    data: function() {
      return {
        title: null,
        loading: false,
        drgTableIds: null,
        optionalParameters: {
          hideWorksheetCoderResponses: false
        },
        showAccuracySummary: true,
        showWorksheetFindings: true,
        showCombinedFindings: true,
        showRebillLog: true,
        showDxFindings: true,
        showPxFindings: true,
        showByFacility: true,
        showByProject: true,
        showByServiceLine: true,
        showByCoder: true,
        showByCDI: true,
        showByDrg: true,
        showByReason: true,
        showPsiSummary: true,
        showQmSummary: true,
        showDx: true,
        showHCC: true,
        showHCCV28: true,
        showPx: true,
        showAccountsReviewed: true,
        showDrgWorksheets: false,
        showFyiWorksheets: false,
        showByMdc: true,
        showDocAssessment: true,
        showTrending: true,
        dashboards: [
          { name: 'Accuracy Summary', component: 'IPBasicSummaryPrintable', showFlag: 'showAccuracySummary', scrollTo: 'accuracySummary', reportSectionOnly: false },
          { name: 'Audit Findings Summary', component: 'IPWorksheetFindingsPrintable', showFlag: 'showWorksheetFindings', scrollTo: 'auditFindings', reportSectionOnly: false },
          { name: 'Combined Code Change Summary', component: 'IPCombinedFindingsPrintable', showFlag: 'showCombinedFindings', scrollTo: 'combinedFindings', reportSectionOnly: false },
          { name: 'Dx Change Summary', component: 'IPDxFindingsPrintable', showFlag: 'showDxFindings', scrollTo: 'dxFindings', reportSectionOnly: false },
          { name: 'Px Change Summary', component: 'IPPxFindingsPrintable', showFlag: 'showPxFindings', scrollTo: 'pxFindings', reportSectionOnly: false },
          { name: 'By Facility', component: 'IPByFacilityPrintable', showFlag: 'showByFacility', scrollTo: 'byFacility', reportSectionOnly: false },
          { name: 'By Project', component: 'IPByProjectPrintable', showFlag: 'showByProject', scrollTo: 'byProject', reportSectionOnly: false },
          { name: 'By Coder', component: 'IPByCoderPrintable', showFlag: 'showByCoder', scrollTo: 'byCoder', reportSectionOnly: false },
          { name: 'IP Trending', component: 'IPTrending', showFlag: 'showTrending', scrollTo: 'ipTrending', reportSectionOnly: true },
          { name: 'By CDI Staff', component: 'IPByCDIStaffPrintable', showFlag: 'showByCDI', scrollTo: 'byCDI', reportSectionOnly: false },
          { name: 'By DRG', component: 'IPByDrgPrintable', showFlag: 'showByDrg', scrollTo: 'byDrg', reportSectionOnly: false },
          { name: 'By Reason', component: 'IPByReasonPrintable', showFlag: 'showByReason', scrollTo: 'byReason', reportSectionOnly: false },
          { name: 'By Service Line', component: 'IPByServiceLinePrintable', showFlag: 'showByServiceLine', scrollTo: 'byServiceLine', reportSectionOnly: false },
          { name: 'By MDC', component: 'IPByMdcPrintable', showFlag: 'showByMdc', scrollTo: 'byMDC', reportSectionOnly: false },
          { name: 'IP Rebill Log', component: 'IPRebillLogPrintable', showFlag: 'showRebillLog', scrollTo: 'rebillLog', reportSectionOnly: false },
          { name: 'IP Documentation Assessment', component: 'IPDocAssessmentPrintable', showFlag: 'showDocAssessment', scrollTo: 'docAssessment', reportSectionOnly: false },
          { name: 'PSI Summary', component: 'IPPsiSummaryPrintable', showFlag: 'showPsiSummary', scrollTo: 'psiSummary', reportSectionOnly: false },
          { name: 'QM Summary', component: 'IPQmSummaryPrintable', showFlag: 'showQmSummary', scrollTo: 'qmSummary', reportSectionOnly: false },
          { name: 'Dx Summary', component: 'IPDxPrintable', showFlag: 'showDx', scrollTo: 'byDx', reportSectionOnly: false },
          { name: 'HCC V24 Summary', component: 'IPHCCPrintable', showFlag: 'showHCC', scrollTo: 'byHCC', reportSectionOnly: false },
          { name: 'HCC V28 Summary', component: 'IPHCCV28Printable', showFlag: 'showHCCV28', scrollTo: 'byHCCV28', reportSectionOnly: false },
          { name: 'Px Summary', component: 'IPPxPrintable', showFlag: 'showPx', scrollTo: 'byPx', reportSectionOnly: false },
          { name: 'DRG Change Worksheets', component: 'IPWorksheetsPrintable', showFlag: 'showDrgWorksheets', scrollTo: 'drgChangeWorksheets', reportSectionOnly: false },
          { name: 'FYI Worksheets', component: 'IPWorksheetsPrintable', showFlag: 'showFyiWorksheets', scrollTo: 'fyiWorksheets', reportSectionOnly: false }
        ]
      }
    },
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      setTitle(evt) {
        window.document.title = evt.target.innerText
      },
      saveReport() {
        window.print()
      },
      scrollTo(id) {
        const target = document.getElementById(id)
        if (target) {
          window.scroll({
            top: target.offsetTop - 195,
            left: 0,
            behavior: 'smooth'
          })
        }
      },
      unselectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = false
        })
      },
      selectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = true
        })
      },
      chunkDashboards(totalChunks) {
        const baseChunkSize = parseInt(this.dashboards.length / totalChunks)
        const remainder = this.dashboards.length % totalChunks
        let offset = 0

        const chunkedResult = []

        for (let i = 1; i <= totalChunks; i++) {
          if (i <= remainder) {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset + 1
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
            offset++
          } else {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
          }
        }

        return chunkedResult
      },
      getShowFlag(showFlag) {
        return this[showFlag]
      },
      toggleShowFlag(showFlag) {
        this[showFlag] = !this[showFlag]
      }
    },
    created: async function() {
      this.loading = true
      const ids = window.localStorage.getItem('reportIds')
      const title = window.localStorage.getItem('reportTitle')
      const isReportSection = window.localStorage.getItem('isReportSection')
      const calcDashboards = this.dashboards

      if (isReportSection === 'false') {
        for (let i = 0; i <= this.dashboards.length - 1; i++) {
          if (this.dashboards[i].reportSectionOnly) {
            calcDashboards.splice(i, 1)
          }
        }
      }
      this.dashboards = calcDashboards
      this.drgTableIds = ids
      this.title = title
      window.document.title = this.title ? `Inpatient Coding Audit Results for ${this.title}` : 'Inpatient Coding Audit Results'
      await this.GET_TENANT_SETUP()
      this.loading = false
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    }
  }
</script>

<style scoped>
  .toc {
    font-size: 14px;
    margin: 8px;
  }

  .toc a {
    color: #606266;
  }

  .toc a:hover {
    color: #337ab7;
    text-decoration: underline;
  }

  .report-header-row {
    box-shadow: 0px 4px 4px -6px rgba(0, 0, 0, 0.8) !important;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 20px 0px 20px 0px;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
  }

  >>> .strong {
    font-weight: bold;
  }

  >>> .emphasis {
    font-style: italic;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .red-font {
    color: red;
  }

  >>> table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
  }

  >>> td,
  >>> th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
  }

  >>> thead {
    text-align: left !important;
    padding-top: 5px;
    /* background-color: #e1f5fe; */
  }

  >>> tr:nth-child(even) {
    background-color: #fafafa;
  }

  >>> .worksheet .demographics tr:nth-child(even) {
    background-color: unset;
  }

  >>> .worksheet .stripe tr:nth-child(even) {
    background-color: unset;
  }

  h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  h2 {
    margin: 50px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .options label {
    margin: 5px 5px 5px 5px;
  }

  .watermark {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    .hidden-lg {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .hidden-sm {
      display: none;
    }
  }

  @media print {
    .watermark {
      display: block;
      font-size: 8px;
      bottom: 0;
      position: fixed;
      right: 10px;
      font-style: italic;
    }

    .page-break {
      display: block;
      page-break-before: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>
