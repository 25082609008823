<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <!-- <h3>{{group.key}}</h3> -->
    <table class="hiaTable" style="margin-top: 20px;">
      <thead>
        <tr>
          <th>Patient Number</th>
          <th>MR Number</th>
          <th>Payor</th>
          <th>Date of Service</th>
          <th>Place of Service</th>
          <th>Orig. CPT</th>
          <th>Orig. Work RVU</th>
          <th>Rec. CPT</th>
          <th>Rec. Work RVU</th>
          <th>RVU Difference</th>
          <th>Reason for Change</th>
        </tr>
      </thead>
      <tr v-for="(item, index) in allData" :key="index">
        <td class="textAlignLeft">{{ item.patientNumber }}</td>
        <td class="textAlignLeft">{{ item.mrNumber }}</td>
        <td class="textAlignLeft">{{ item.payorName }}</td>
        <td class="textAlignLeft">{{ format(parseISO(item.dateOfService), 'M/d/yyyy') }}</td>
        <td class="textAlignLeft">{{ item.posDescription }}</td>
        <td class="textAlignLeft">{{ item.originalCpt }}</td>
        <td>{{ item.origWorkRvu }}</td>
        <td class="textAlignLeft">
          <div :class="`${item.deleteCode ? 'line-through' : ''}`">{{ item.deleteCode ? item.originalCpt : item.recommendedCpt }}</div>
        </td>
        <td>{{ item.recommendedWorkRvu }}</td>
        <td>
          <div :class="`${item.rvuDiff < 0 ? 'negative' : ''}`">{{ item.rvuDiff }}</div>
        </td>
        <td class="textAlignLeft">{{ item.reasonForChange }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'PRO_RebillLogPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'codeTypes'],
    components: {},
    data: function() {
      return {
        format: format,
        parseISO: parseISO,
        allData: [],
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proRebillLog`, payload)
          this.allData = response.data
          this.tableData = response.data
        } catch (err) {
          this.allData = []
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [{ property: 'coder' }, { property: 'totalRecords' }, { property: 'totalCodes' }, { property: 'cmCodes' }, { property: 'emCodes' }, { property: 'cptCodes' }, { property: 'modCodes' }, { property: 'rvuDiff' }],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function() {
      await this.getData()
    },
    watch: {
      proRecordIds: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }
  .line-through {
    text-decoration: line-through;
  }
</style>
