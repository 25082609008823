<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <table class="hiaTable">
      <thead>
        <tr>
          <th>Mortality Measure</th>
          <th style="width: 30%">Description</th>
          <th>Total Records</th>
          <th>No Rec</th>
          <th>Additions</th>
          <th>Deletions</th>
          <th>Accuracy Rate</th>
        </tr>
      </thead>
      <tr v-for="(row, i) in tableData.mortalityQmSummarys" :key="i">
        <td class="textAlignLeft">{{row.shortDescription}}</td>
        <td class="textAlignLeft">{{row.longDescription}}</td>
        <td>{{row.noRec + row.additions + row.deletions}}</td>
        <td>{{row.noRec}}</td>
        <td>{{row.additions}}</td>
        <td>{{row.deletions}}</td>
        <td>{{formatPercentage(row.accuracy)}}</td>
      </tr>
      <tr class="strong emphasis" v-if="tableData.mortalityQmSummarys.length > 0">
        <td class="textAlignLeft">Totals</td>
        <td></td>
        <td>{{mortalitySummary[3] + mortalitySummary[4] + mortalitySummary[5]}}</td>
        <td>{{mortalitySummary[3]}}</td>
        <td>{{mortalitySummary[4]}}</td>
        <td>{{mortalitySummary[5]}}</td>
        <td>{{mortalitySummary[6]}}</td>
      </tr>
      <tr v-if="tableData.mortalityQmSummarys.length === 0">
        <td colspan="7" class="textAlignLeft">{{loading ? 'loading...' : 'No Mortality Quality Measure Data Found'}}</td>
      </tr>
    </table>

    <table class="hiaTable" style="margin-top: 30px;">
      <thead>
        <tr>
          <th>Readmission Measure</th>
          <th style="width: 30%">Description</th>
          <th>Total Records</th>
          <th>No Rec</th>
          <th>Additions</th>
          <th>Deletions</th>
          <th>Accuracy Rate</th>
        </tr>
      </thead>
      <tr v-for="(row, i) in tableData.readmissionQmSummarys" :key="i">
        <td class="textAlignLeft">{{row.shortDescription}}</td>
        <td class="textAlignLeft">{{row.longDescription}}</td>
        <td>{{row.noRec + row.additions + row.deletions}}</td>
        <td>{{row.noRec}}</td>
        <td>{{row.additions}}</td>
        <td>{{row.deletions}}</td>
        <td>{{formatPercentage(row.accuracy)}}</td>
      </tr>
      <tr class="strong emphasis" v-if="tableData.readmissionQmSummarys.length > 0">
        <td class="textAlignLeft">Totals</td>
        <td></td>
        <td>{{readmissionSummary[3] + readmissionSummary[4] + readmissionSummary[5]}}</td>
        <td>{{readmissionSummary[3]}}</td>
        <td>{{readmissionSummary[4]}}</td>
        <td>{{readmissionSummary[5]}}</td>
        <td>{{readmissionSummary[6]}}</td>
      </tr>
      <tr v-if="tableData.readmissionQmSummarys.length === 0">
        <td colspan="7" class="textAlignLeft">{{loading ? 'loading...' : 'No Readmission Quality Measure Data Found'}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import dateHelper from '@/mixins/date-helper'

  export default {
    name: 'IP_QmSummaryPrintable',
    mixins: [hiaFilters, money, dashboardHelper, dateHelper],
    props: ['drgTableIds'],
    components: {},
    data: function () {
      return {
        tableData: { mortalityQmSummarys: [], readmissionQmSummarys: [] },
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {}
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipQmSummary', payload)
          this.tableData = response.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      mortalitySummary: function () {
        const param = {
          columns: [
            { property: 'shortDescription' },
            { property: 'longDescription' },
            { property: '' },
            { property: 'noRec' },
            { property: 'additions' },
            { property: 'deletions' },
            { property: 'accuracy' }
          ],
          data: this.tableData.mortalityQmSummarys
        }
        return this.getSummary(param)
      },
      readmissionSummary: function () {
        const param = {
          columns: [
            { property: 'shortDescription' },
            { property: 'longDescription' },
            { property: '' },
            { property: 'noRec' },
            { property: 'additions' },
            { property: 'deletions' },
            { property: 'accuracy' }
          ],
          data: this.tableData.readmissionQmSummarys
        }
        return this.getSummary(param)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
.hiaTable thead th {
  text-align: center;
}

.hiaTable tr td {
  text-align: right;
}
</style>
