<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <el-row v-if="!loading">
      <el-col :span="16">
        <table class="totalsTable hiaTable">
          <thead>
            <tr>
              <th>Records Reviewed With Dx Codes</th>
              <th>Records With Dx Findings</th>
              <th>Records Without Dx Findings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{recordTotals.totalRecords}}</td>
              <td>{{recordTotals.recordsWithFindings}}</td>
              <td>{{recordTotals.recordsWithoutFindings}}</td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <el-row v-for="codeType in codeTypesAndData" style="margin-top: 30px; margin-bottom:20px;" :key="codeType.codeDesc">
      <h3>{{codeType.codeDesc}}</h3>

      <el-col :span="14">
        <table class="hiaTable" style="margin-top: 20px;">
          <thead>
            <tr>
              <th> Description </th>
              <th> Frequency </th>
              <th> % of {{codeType.codeDesc}} </th>
            </tr>
          </thead>
          <tr v-for="item in codeType.data" :key="item.description">
            <td class="textAlignLeft"> {{item.description}} </td>
            <td> {{item.numberOfCodes}} </td>
            <td> {{formatPercentage(item.percentOfTotal)}} </td>
          </tr>
          <tr class="strong emphasis">
            <td class="textAlignLeft">Total</td>
            <td>{{getSummaries(codeType.data)[1]}}</td>
            <td>N/A</td>
          </tr>
        </table>

      </el-col>
      <el-col :span="10">
        <pieChart :data="codeType.data" :nameValue="'description'" :yValue="'percentOfTotal'"></pieChart>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import pieChart from '../highcharts/pieChart'

  export default {
    name: 'ASC_FindingSummaryDXPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'codeTypes'],
    components: {
      pieChart
    },
    data: function () {
      return {
        tableData: [],
        recordTotals: {},
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/opDxFindingSummary`, payload)
          this.tableData = response.data.largeStat.data
          this.recordTotals = response.data.recordTotals
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'description' },
            { property: 'numberOfCodes' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      codeTypesAndData() {
        const codeTypeList = [...new Set(this.tableData.map(x => x.codeDesc))]
        const codeTypesAndData = []
        for (let i = 0; i < codeTypeList.length; i++) {
          codeTypesAndData.push({
            codeDesc: codeTypeList[i],
            data: this.tableData.filter(x => x.codeDesc === codeTypeList[i])
          })
        }

        return codeTypesAndData
      }
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }
</style>