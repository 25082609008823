<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <v-server-table style="min-height: 120px;" class="hiaTable" id="reviewListGrid" ref="reviewListGrid" @loading="tableLoader = true" @loaded="tableLoader = false"
      :columns="columns" :options="options" v-loading="tableLoader" element-loading-spinner="atom-audit-loader">
      <!-- <template slot="selected" slot-scope="props">
        <input type="checkbox" v-model="props.row.checked" @click="selectRecord(props.row, $event)">
      </template> -->
      <template slot="projectName" slot-scope="props">
        <div>
          <router-link v-bind:to="`/projects/${props.row.projectId}`">{{props.row.projectName}}
          </router-link>
        </div>
      </template>
      <template slot="patientNumber" slot-scope="props">
        <div>
          <router-link v-bind:to="`/reviews/inpatient/${props.row.drgTableId}`">{{props.row.patientNumber}}
          </router-link>
        </div>
      </template>

      <template slot="recordTags" slot-scope="props">
        <template v-if="props.row.recordTags">
          <div class="el-tag el-tag--mini el-tag--light" style="max-width: 100px; " v-for="tag in props.row.recordTags.split(',')" :key="tag">
            <div :title="tag" style="overflow: hidden; text-overflow: ellipsis;">
              {{ tag }}
            </div>
          </div>
        </template>
      </template>

      <template slot="dischargeDate" slot-scope="props">
        <div>
          {{ props.row.dischargeDate ? formatDate(props.row.dischargeDate) : '' }}
        </div>
      </template>
      <template slot="admitDate" slot-scope="props">
        <div>
          {{ props.row.admitDate ? formatDate(props.row.admitDate) : '' }}
        </div>
      </template>
      <template slot="reviewDate" slot-scope="props">
        <div>
          {{ props.row.reviewDate ? formatDate(props.row.reviewDate) : '' }}
        </div>
      </template>
      <template slot="level2ReasonForChage" slot-scope="props">
        <div>
          {{ props.row.reviewLevel !== 'Coding Review' ? props.row.level2ReasonForChage : '' }}
        </div>
      </template>
      <template slot="hasAP" slot-scope="props">
        <div v-if="props.row.hasAP != null">
          <center>
            <i class="el-icon-check checkSize" :title="props.row.hasAP"></i>
          </center>
        </div>
      </template>
      <template slot="comments" slot-scope="props">
        <div v-if="props.row.comments != null">
          <span :title="props.row.comments">{{ `${props.row.comments.substring(1,30)}...` }}</span>
        </div>
      </template>
      <template slot="delete" slot-scope="props">
        <el-popover :popper-class="'delete_'+props.row.drgTableId" trigger="click" placement="left" width="190">
          <center>
            <p>Delete this Chart?</p>
            <el-button type="primary" size="mini" @click="deleteChart(props)">confirm</el-button>
          </center>
          <div slot="reference">
            <!-- <button :disabled="tableLoader" size="mini" type="button" title="Delete Chart" class="deleteButton" style="float: right;"></button> -->
            <div style="text-align: center; cursor: pointer;">
              <i class="el-icon-close delete-button"></i>
            </div>
          </div>
        </el-popover>
      </template>
    </v-server-table>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'inpatientListFilter',
    mixins: [dateHelper, hiaTable],
    data() {
      return {
        localInpatientData: {},
        filterCriteria: '',
        tableLoader: false,
        loading: false,
        vueTables2Query: {},
        columns: ['projectName', 'patientNumber', 'patientType', 'consultantNumber', 'recordTags', 'mrNumber', 'payorName', 'coder', 'cdiStaff', 'physicianName', 'dischargeDate', 'grouper', 'status', 'reasonForChange', 'originalDrg', 'newDrg', 'patientAge', 'originalPoo', 'disposition', 'recommendedPatStatus', 'recommendedPoo', 'reviewDate'],
        options: {
          requestFunction: (data) => {
            return this.requestData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          sortable: ['projectName', 'identifiers', 'hasAP', 'patientNumber', 'patientType', 'consultantNumber', 'recordTags', 'mrNumber', 'payorName', 'coder', 'physicianName', 'dischargeDate', 'grouper', 'reasonForChange', 'originalDrg', 'originalWeight', 'newDrg', 'newWeight', 'patientAge', 'originalPoo', 'admitDate', 'disposition', 'recommendedPatStatus', 'ipreviewType', 'recommendedPoo', 'subClientNumber', 'originalReviewer', 'originalReviewDate', 'originalClientNumber', 'level2ReasonForChage', 'level2Drg', 'l2drgweight', 'level2PatientStatus', 'level2Poo', 'reviewLevel', 'cdiStaff', 'reviewDate', 'comments', 'status'],
          orderBy: {
            column: 'patientNumber',
            ascending: true
          },
          columnsClasses: {
            selected: 'selected',
            // excludeRecord: 'hideColumn',
            // ipreviewType: 'hideColumn',
            // originalReviewer: 'hideColumn',
            // originalReviewDate: 'hideColumn',
            // originalClientNumber: 'hideColumn',
            // reviewLevel: 'hideColumn',
            comments: 'customComments',
            delete: 'delete'
          },
          headings: {
            selected: (h) => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'selectAllCheckboxIpList'
                },
                on: {
                  click: (e) => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            },
            projectName: 'Project',
            identifiers: 'Project Tags',
            status: 'Review Status',
            patientNumber: 'Patient Number',
            patientType: 'Pat Type',
            consultantNumber: 'Auditor',
            recordTags: 'Record Tags',
            payorName: 'Payor',
            physicianName: 'Provider',
            dischargeDate: 'Discharge Date',
            reasonForChange: 'Reason F Change',
            originalDrg: 'Orig. DRG',
            newDrg: 'New DRG',
            patientAge: 'Age',
            originalPoo: 'POO',
            disposition: 'Pat Status',
            recommendedPatStatus: 'Rec Pat Status',
            ipreviewType: 'Review Type',
            excludeRecord: 'Exclude Record',
            dedicatedStaffReview: 'Dedicated Staff Review',
            recommendedPoo: 'Rec POO',
            subClientNumber: 'Sub Client',
            originalReviewer: 'Orig Reviewer',
            originalReviewDate: 'Orig Review Date',
            originalClientNumber: 'Orig Client',
            level2ReasonForChage: 'Lvl2 Reason F Change',
            level2Drg: 'Lvl2 DRG',
            level2PatientStatus: 'Lvl2 Pat Status',
            level2Poo: 'Lvl2 POO',
            reviewLevel: 'Review Level',
            cdiStaff: 'CDI Staff',
            comments: 'Comments',
            mrNumber: 'MR Number',
            recordStatus: 'Record Status',
            reviewDate: 'Review Date',
            hasAP: 'Has Action Plan',
            delete: ''
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        }
      }
    },
    methods: {
      ...mapActions('reports/', ['LOAD_IPREPORTDATA_FILTERED']),
      ...mapMutations('reports/', ['SET_REPORTS_INPATIENT_VUETABLEDATA']),
      async requestData(data) {
        try {
          this.SET_REPORTS_INPATIENT_VUETABLEDATA(data)
          const payload = {
            inpatientReport: this.inpatientReport
          }
          this.statsLoading = true
          await Promise.all([this.LOAD_IPREPORTDATA_FILTERED(payload)])
          this.statsLoading = false
          const ipResults = {
            count: this.inpatientReport.inpatientData.paginatedCount,
            data: this.inpatientReport.inpatientData.inpatientListView
          }
          return ipResults
        } catch (err) {
          console.log(err)
        }
      },
      refreshData() {
        this.$refs.reviewListGrid.getData()
      },
      showAddMoreColumns() {
        const popper = this.$refs.sortableColumns
        popper.referenceElm = this.$refs.sortableColumnsRef
        popper.doShow()
      }
    },
    watch: {},
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('reports/', ['inpatientReport'])
    },
    created: function () {
      // this.localInpatientData = JSON.parse(JSON.stringify(this.reports.inpatientData))
    },
    components: {}
  }
</script>

<style scoped>
  >>>.VueTables__filters-row {
    display: none;
  }

  >>>.hiaTable>.table-responsive {
    overflow-x: auto;
  }
</style>