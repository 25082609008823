import { render, staticRenderFns } from "./ASC_ByPatientTypePrintable.vue?vue&type=template&id=2c454fc3&scoped=true&"
import script from "./ASC_ByPatientTypePrintable.vue?vue&type=script&lang=js&"
export * from "./ASC_ByPatientTypePrintable.vue?vue&type=script&lang=js&"
import style0 from "./ASC_ByPatientTypePrintable.vue?vue&type=style&index=0&id=2c454fc3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c454fc3",
  null
  
)

export default component.exports