<template>
  <div class="table-wrapper">
    <h2 id="OPSummaryTitle" v-if="title"> {{ title }} </h2>
    <table class="hiaTable">
      <thead>
        <tr>
          <th style="width: 190px;"> </th>
          <th> Total Codes </th>
          <th> ICD-10 CM Codes </th>
          <th> CPT with Reimb. </th>
          <th> CPT without Reimb. </th>
          <th> Modifier Codes </th>
        </tr>
      </thead>
      <template v-if="item.totalRecords">
        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol textAlignLeft"> Total Reviewed </td>
          <td> {{item.totalCodesReviewed}} </td>
          <td> {{item.dxTotalReviewed}} </td>
          <td> {{item.asc_reimbTotal}} </td>
          <td> {{item.asc_noReimbTotal}} </td>
          <td> {{item.modTotalReviewed}} </td>
        </tr>

        <tr>
          <td class="strong titleCol textAlignLeft"> Total Recommendations </td>
          <td> {{item.totalCodesRecommended}} </td>
          <td> {{item.dxTotalRec}} </td>
          <td> {{item.asc_reimbTotalRec}} </td>
          <td> {{item.asc_noReimbTotalRec}} </td>
          <td> {{item.modTotalRec}} </td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol textAlignLeft"> No Recommendations </td>
          <td> {{item.totalCodesNoRec}} </td>
          <td> {{item.dxNoRec}} </td>
          <td> {{item.asc_numCptReimbNoRec}} </td>
          <td> {{item.asc_numCptNoReimbNoRec}} </td>
          <td> {{item.modNoRec}} </td>
        </tr>

        <tr>
          <td class="strong titleCol textAlignLeft"> Additions </td>
          <td> {{item.totalCodesAdditions}} </td>
          <td>{{item.dxAdditions}} </td>
          <td> {{item.asc_numCptReimbAdditions}} </td>
          <td> {{item.asc_numCptNoReimbAdditions}} </td>
          <td>{{item.modAdditions}} </td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol textAlignLeft"> Deletions </td>
          <td> {{item.totalCodesDeletions}} </td>
          <td> {{item.dxDeletions}} </td>
          <td> {{item.asc_numCptReimbDeletions}} </td>
          <td> {{item.asc_numCptNoReimbDeletions}} </td>
          <td> {{item.modDeletions}} </td>
        </tr>

        <tr>
          <td class="strong titleCol textAlignLeft"> Revisions </td>
          <td> {{item.totalCodesRev}} </td>
          <td> {{item.dxRevisions}} </td>
          <td> {{item.asc_numCptReimbRevisions}} </td>
          <td> {{item.asc_numCptNoReimbRevisions}} </td>
          <td> {{item.modRevisions}} </td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol textAlignLeft"> Accuracy Rate </td>
          <td> {{ !item.totalCodesReviewed ? 'N/A' : formatPercentage(item.totalCodes) }} </td>
          <td> {{ !item.dxTotalReviewed ? 'N/A' : formatPercentage(item.cmCodes) }} </td>
          <td> {{ !item.asc_reimbTotal ? 'N/A' : formatPercentage(item.cptWithReimb) }} </td>
          <td> {{ !item.asc_noReimbTotal ? 'N/A' : formatPercentage(item.cptWithoutReimb) }} </td>
          <td> {{ !item.modTotalReviewed ? 'N/A' : formatPercentage(item.modCodes) }} </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  export default {
    name: 'IPBasicSummary',
    props: ['item', 'title'],
    mixins: [money, dashboardHelper],
    data: function () {
      return {
      }
    },
    methods: {}
  }
</script>

<style scoped>
  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>
