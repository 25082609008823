<template>
  <div class="auditorStatusDashboards">
    <el-row :gutter="10">
      <el-col :lg="6" :md="8" :sm="12">
        <el-date-picker class="pull-right hiaRangePicker" size="mini" format="MM/dd/yyyy" value-format="MM/dd/yyyy" v-model="localdateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1;" :picker-options="dateRangePickerConfig" :clearable="false">
        </el-date-picker>
      </el-col>
      <el-col :lg="18" :md="16" :sm="12">
        <i title="Filter Section" class="mdi mdi-filter-variant filterIcon"></i>
        <div class="filterBlock">
          <hia-el-select title="group by date" :disabled="auditorDashboardLoading" v-model="dateFilter" placeholder="Group by Date" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="groupDateDropdown" @input="handleCriteriaChange"></hia-el-select>
        </div>
        <div class="filterBlock">
          <el-select :disabled="auditorDashboardLoading" v-model="statusFilter" placeholder="Filter by Status" size="mini" filterable clearable multiple @change="handleCriteriaChange">
            <el-option title="filter by status" v-for="item in filterStatusDropdown" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div class="filterBlock">
          <el-select title="filter by user" :disabled="auditorDashboardLoading" v-model="userFilter" placeholder="Filter by User" size="mini" filterable clearable multiple @change="handleCriteriaChange">
            <el-option v-for="item in auditorDashboardUsers" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0px 0px 0px;" v-loading="auditorDashboardLoading" element-loading-spinner="atom-audit-loader">
      <h3>Status Totals</h3>
      <lineChart :categories="auditorDashboard.categories" :series="auditorDashboard.statusSeries" xTitle="Date Range" yTitle="Status Totals"></lineChart>
    </el-row>
    <el-row style="margin: 20px 0px 0px 0px;" v-loading="auditorDashboardLoading" element-loading-spinner="atom-audit-loader">
      <h3>User Totals</h3>
      <lineChart :categories="auditorDashboard.categories" :series="auditorDashboard.userSeries" xTitle="Date Range" yTitle="User Totals"></lineChart>
    </el-row>
    <el-row>
      <h3>Summary Data</h3>
      <v-client-table style="min-height: 120px;" class="hiaTable" ref="auditorDashboard" id="auditorDashboard" :data="auditorDashboard.allData" :columns="auditorDashboardColumns" :options="auditorDashboardOptions" v-loading="auditorDashboardLoading" element-loading-spinner="atom-audit-loader">
        <template slot="dateField" slot-scope="props">
          <div>
            {{ props.row.dateField ? formatDate(props.row.dateField) : '' }}
          </div>
        </template>
        <template slot="details" slot-scope="props">
          <div>
            <el-button plain round size="mini" icon="mdi mdi-details" title="Records Included" circle @click.native.prevent="updateAcctList(props.row)">
            </el-button>
          </div>
        </template>
      </v-client-table>
    </el-row>
    <div class="dialogs">
      <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
        <accountList v-if="dialogVisible" :header="detailsHeader" :ascTableIds="ascTableIds" :drgTableIds="drgTableIds" :proRecordIds="proRecordIds"></accountList>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import lineChart from './highcharts/lineChart'
  import accountList from './dashboards/accoutList'

  export default {
    name: 'auditorStatusDashboards',
    mixins: [dateHelper, hiaTable, HiaAuthorization],
    data() {
      return {
        ascTableIds: null,
        drgTableIds: null,
        proRecordIds: null,
        dialogVisible: false,
        dateFilter: 'dateField',
        statusFilter: [],
        userFilter: [],
        localdateRange: [],
        auditorDashboardLoading: false,
        groupDateDropdown: [
          { key: 'dateField', value: 'Daily' },
          { key: 'firstDayOfWeek', value: 'Weekly' },
          { key: 'firstDayOfMonth', value: 'Monthly' }
        ],
        filterStatusDropdown: [
          { key: 'Not Started', value: 'Not Started' },
          { key: 'In Progress', value: 'In Progress' },
          { key: 'Completed', value: 'Completed' },
          { key: 'Ready for Coder', value: 'Ready for Coder' }
        ],
        auditorDashboardColumns: ['dateField', 'recordType', 'status', 'fullName', 'uniqueRecords', 'details'],
        auditorDashboardOptions: {
          // see the options API
          filterByColumn: false,
          listColumns: {
            identifier: []
          },
          columnsClasses: {
            // projectName: 'wideColumn'
          },
          orderBy: {
            column: 'dateField',
            ascending: true
          },
          headings: {
            dateField: 'Date',
            recordType: 'Record Type',
            status: 'Status',
            fullName: 'User',
            uniqueRecords: 'Records',
            details: 'Details'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          filterAlgorithm: {}
        }
      }
    },
    methods: {
      ...mapActions('reports/dashboards/', ['LOAD_AUDITOR_DASHBOARD', 'GET_AUDITOR_DASHBOARD_USERS']),
      ...mapMutations('reports/', ['SET_REPORTS_DEFAULTDATERANGE', 'SET_REPORTS_DEFAULTCOMPLETED', 'SET_REPORTS_OUTPATIENTGROUPERS']),
      async initialize() {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        this.localdateRange = [this.formatDate(start), this.formatDate(end)]
        const payload = {
          startDate: this.localdateRange[0],
          endDate: this.localdateRange[1],
          filters: [
            {
              column: 'dateField',
              columnLabel: 'Date',
              columnType: 'date',
              filterValues: [],
              filterText: this.dateFilter,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            },
            {
              column: 'userId',
              columnLabel: 'User',
              columnType: 'int',
              filterValues: this.userFilter,
              filterText: null,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            },
            {
              column: 'status',
              columnLabel: 'Status',
              columnType: 'text',
              filterValues: this.statusFilter,
              filterText: null,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            }
          ]
        }
        this.auditorDashboardLoading = true
        await this.GET_AUDITOR_DASHBOARD_USERS()
        await this.LOAD_AUDITOR_DASHBOARD(payload)
        this.auditorDashboardLoading = false
      },
      async handleCriteriaChange() {
        const payload = {
          startDate: this.localdateRange[0],
          endDate: this.localdateRange[1],
          filters: [
            {
              column: 'dateField',
              columnLabel: 'Date',
              columnType: 'date',
              filterValues: [],
              filterText: this.dateFilter,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            },
            {
              column: 'userId',
              columnLabel: 'User',
              columnType: 'int',
              filterValues: this.userFilter,
              filterText: null,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            },
            {
              column: 'status',
              columnLabel: 'Status',
              columnType: 'text',
              filterValues: this.statusFilter,
              filterText: null,
              moreThanDate: 0,
              withinDate: 0,
              dateOption: 'days',
              dateCriteria: null,
              tagCriteria: null,
              uniqueValues: [],
              dateBetween: [],
              active: true,
              includeUniqueValues: true,
              reportType: 'auditorDashboard'
            }
          ]
        }
        this.auditorDashboardLoading = true
        await this.LOAD_AUDITOR_DASHBOARD(payload)
        this.auditorDashboardLoading = false
      },
      updateAcctList(row) {
        switch (row.recordType) {
          case 'Inpatient':
            this.drgTableIds = row.tableIds
            this.ascTableIds = null
            this.proRecordIds = null
            break
          case 'Outpatient':
            this.drgTableIds = null
            this.ascTableIds = row.tableIds
            this.proRecordIds = null
            break
          case 'Professional Fee':
            this.drgTableIds = null
            this.ascTableIds = null
            this.proRecordIds = row.tableIds
            break
        }
        this.detailsHeader = `${row.fullName} on ${this.formatDate(row.dateField)}`
        this.dialogVisible = !this.dialogVisible
      }
    },
    watch: {},
    computed: {
      ...mapState('reports/dashboards/', ['auditorDashboard', 'auditorDashboardUsers']),
      ...mapState('globals/', ['dateRangePickerConfig']),
      ...mapState('user/', ['user', 'currentTenantUserId', 'baseApiUrl'])
    },
    created: function () {
      this.initialize()
    },
    components: {
      lineChart,
      accountList
    }
  }
</script>

<style scoped>
>>> .VueTables__search {
  display: none;
}
.hiaRangePicker {
  margin: 2px 0px 0px 0px;
}
.filterBlock {
  font-size: 11px;
  float: left;
  margin: 2px 0px 0px 3px;
}
</style>
