<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-col :span="16">
          <h3>{{groups.summaryAtTimeOfCoding.title}}</h3>
          <!-- <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `summaryAtTimeOfCoding`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <table :id="`summaryAtTimeOfCoding`" :ref="`summaryAtTimeOfCoding`" class="hiaTable" style="margin-top: 20px;">
            <thead>
              <tr>
                <th> Description </th>
                <th> Frequency </th>
                <th> % </th>
              </tr>
            </thead>
            <tr v-for="item in groups.summaryAtTimeOfCoding.data" :key="item.value">
              <td class="textAlignLeft"> <span :style="`color: ${item.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span> <span style="float: left; margin: 4px 0px 0px 0px;">{{item.value}}</span> </td>
              <td> {{item.count}} </td>
              <td> {{formatPercentage(item.percentage)}} </td>
            </tr>
            <tr class="strong emphasis">
              <td class="textAlignLeft">Total</td>
              <td>{{tableData.length}}</td>
            </tr>
          </table>

          <!-- <el-table :id="`summaryAtTimeOfCoding`" class="tableNoWrap" :ref="`summaryAtTimeOfCoding`" :data="groups.summaryAtTimeOfCoding.data" empty-text="No Data Available" stripe :summary-method="getSummaries" show-summary :cell-class-name="cellClasses" row-key="value">
            <el-table-column prop="value" label="Description" sortable>
              <template slot-scope="scope">
                <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
                <div v-html="highlightValue(scope.row.value, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Frequency" sortable>
              <template slot-scope="scope">
                <div tag="count" :title="scope.row.count" v-html="highlightValue(scope.row.count, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="percentage" :label="`%`" sortable>
              <template slot-scope="scope">
                {{formatPercentage(scope.row.percentage)}}
              </template>
            </el-table-column>
            <el-table-column label="Details" width="120" prop="details">
              <template slot-scope="scope">
                <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle>
                </el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div>
            <p style="font-size: 12px;">{{groups.summaryAtTimeOfCoding.footerText}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <pieChart v-if="!loading" :data="groups.summaryAtTimeOfCoding.data" :nameValue="'value'" :yValue="'percentage'"></pieChart>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-col :span="16">
          <h3>{{groups.summaryAtTimeOfReview.title}}</h3>

          <table :id="`summaryAtTimeOfReview`" :ref="`summaryAtTimeOfReview`" class="hiaTable" style="margin-top: 20px;">
            <thead>
              <tr>
                <th> Description </th>
                <th> Frequency </th>
                <th> % </th>
              </tr>
            </thead>
            <tr v-for="item in groups.summaryAtTimeOfReview.data" :key="item.value">
              <td class="textAlignLeft"> <span :style="`color: ${item.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span> <span style="float: left; margin: 4px 0px 0px 0px;">{{item.value}}</span> </td>
              <td> {{item.count}} </td>
              <td> {{formatPercentage(item.percentage)}} </td>
            </tr>
            <tr class="strong emphasis">
              <td class="textAlignLeft">Total</td>
              <td>{{tableData.length}}</td>
            </tr>
          </table>

          <!-- <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `summaryAtTimeOfReview`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <!-- <el-table :id="`summaryAtTimeOfReview`" class="tableNoWrap" :ref="`summaryAtTimeOfReview`" :data="groups.summaryAtTimeOfReview.data" empty-text="No Data Available" stripe :summary-method="getSummaries" show-summary :cell-class-name="cellClasses" row-key="value">
            <el-table-column prop="value" label="Description" sortable>
              <template slot-scope="scope">
                <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
                <div v-html="highlightValue(scope.row.value, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Frequency" sortable>
              <template slot-scope="scope">
                <div tag="count" :title="scope.row.count" v-html="highlightValue(scope.row.count, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="percentage" :label="`%`" sortable>
              <template slot-scope="scope">
                {{formatPercentage(scope.row.percentage)}}
              </template>
            </el-table-column>
            <el-table-column label="Details" width="120" prop="details">
              <template slot-scope="scope">
                <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle>
                </el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div>
            <p style="font-size: 12px;">{{groups.summaryAtTimeOfReview.footerText}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <pieChart v-if="!loading" :data="groups.summaryAtTimeOfReview.data" :nameValue="'value'" :yValue="'percentage'"></pieChart>
        </el-col>
      </el-col>
    </el-row>

    <el-row style="margin: 10px 0px 0px 0px;" :gutter="10">
      <el-col :span="12">
        <el-col :span="16">
          <h3>{{groups.admitDate.title}}</h3>

          <table :id="`admitDate`" :ref="`admitDate`" class="hiaTable" style="margin-top: 20px;">
            <thead>
              <tr>
                <th> Description </th>
                <th> Frequency </th>
                <th> % </th>
              </tr>
            </thead>
            <tr v-for="item in groups.admitDate.data" :key="item.value">
              <td class="textAlignLeft"> <span :style="`color: ${item.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span> <span style="float: left; margin: 4px 0px 0px 0px;">{{item.value}}</span> </td>
              <td> {{item.count}} </td>
              <td> {{formatPercentage(item.percentage)}} </td>
            </tr>
            <tr class="strong emphasis">
              <td class="textAlignLeft">Total</td>
              <td>{{tableData.length}}</td>
            </tr>
          </table>

          <!-- <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `admitDate`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <!-- <el-table :id="`admitDate`" class="tableNoWrap" :ref="`admitDate`" :data="groups.admitDate.data" empty-text="No Data Available" stripe :summary-method="getSummaries" show-summary :cell-class-name="cellClasses" row-key="value">
            <el-table-column prop="value" label="Description" sortable>
              <template slot-scope="scope">
                <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
                <div v-html="highlightValue(scope.row.value, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Frequency" sortable>
              <template slot-scope="scope">
                <div tag="count" :title="scope.row.count" v-html="highlightValue(scope.row.count, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="percentage" :label="`%`" sortable>
              <template slot-scope="scope">
                {{formatPercentage(scope.row.percentage)}}
              </template>
            </el-table-column>
            <el-table-column label="Details" width="120" prop="details">
              <template slot-scope="scope">
                <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle>
                </el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div>
            <p style="font-size: 12px;">{{groups.admitDate.footerText}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <pieChart v-if="!loading" :data="groups.admitDate.data" :nameValue="'value'" :yValue="'percentage'"></pieChart>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-col :span="16">
          <h3>{{groups.missingAppropriateOrder.title}}</h3>

          <table :id="`missingAppropriateOrder`" :ref="`missingAppropriateOrder`" class="hiaTable" style="margin-top: 20px;">
            <thead>
              <tr>
                <th> Description </th>
                <th> Frequency </th>
                <th> % </th>
              </tr>
            </thead>
            <tr v-for="item in groups.missingAppropriateOrder.data" :key="item.value">
              <td class="textAlignLeft"> <span :style="`color: ${item.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span> <span style="float: left; margin: 4px 0px 0px 0px;">{{item.value}}</span> </td>
              <td> {{item.count}} </td>
              <td> {{formatPercentage(item.percentage)}} </td>
            </tr>
            <tr class="strong emphasis">
              <td class="textAlignLeft">Total</td>
              <td>{{tableData.length}}</td>
            </tr>
          </table>

          <!-- <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `missingAppropriateOrder`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-table :id="`missingAppropriateOrder`" class="tableNoWrap" :ref="`missingAppropriateOrder`" :data="groups.missingAppropriateOrder.data" empty-text="No Data Available" stripe :summary-method="getSummaries" show-summary :cell-class-name="cellClasses" row-key="value">
            <el-table-column prop="value" label="Description" sortable>
              <template slot-scope="scope">
                <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
                <div v-html="highlightValue(scope.row.value, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Frequency" sortable>
              <template slot-scope="scope">
                <div tag="count" :title="scope.row.count" v-html="highlightValue(scope.row.count, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="percentage" :label="`%`" sortable>
              <template slot-scope="scope">
                {{formatPercentage(scope.row.percentage)}}
              </template>
            </el-table-column>
            <el-table-column label="Details" width="120" prop="details">
              <template slot-scope="scope">
                <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle>
                </el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div>
            <p style="font-size: 12px;">{{groups.missingAppropriateOrder.footerText}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <pieChart v-if="!loading" :data="groups.missingAppropriateOrder.data" :nameValue="'value'" :yValue="'percentage'"></pieChart>
        </el-col>
      </el-col>
    </el-row>

    <el-row style="margin: 20px 0px 0px 0px;" :gutter="10">
      <el-col :span="12">
        <el-col :span="16">
          <h3>{{groups.cloning.title}}</h3>
          <table :id="`cloning`" :ref="`cloning`" class="hiaTable" style="margin-top: 20px;">
            <thead>
              <tr>
                <th> Description </th>
                <th> Frequency </th>
                <th> % </th>
              </tr>
            </thead>
            <tr v-for="item in groups.cloning.data" :key="item.value">
              <td class="textAlignLeft"> <span :style="`color: ${item.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span> <span style="float: left; margin: 4px 0px 0px 0px;">{{item.value}}</span> </td>
              <td> {{item.count}} </td>
              <td> {{formatPercentage(item.percentage)}} </td>
            </tr>
            <tr class="strong emphasis">
              <td class="textAlignLeft">Total</td>
              <td>{{tableData.length}}</td>
            </tr>
          </table>

          <!-- <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `cloning`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
           <el-table :id="`cloning`" class="tableNoWrap" :ref="`cloning`" :data="groups.cloning.data" empty-text="No Data Available" stripe :summary-method="getSummaries" show-summary :cell-class-name="cellClasses" row-key="value">
            <el-table-column prop="value" label="Description" sortable>
              <template slot-scope="scope">
                <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
                <div v-html="highlightValue(scope.row.value, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Frequency" sortable>
              <template slot-scope="scope">
                <div tag="count" :title="scope.row.count" v-html="highlightValue(scope.row.count, filterCriteria)"></div>
              </template>
            </el-table-column>
            <el-table-column prop="percentage" :label="`%`" sortable>
              <template slot-scope="scope">
                {{formatPercentage(scope.row.percentage)}}
              </template>
            </el-table-column>
            <el-table-column label="Details" width="120" prop="details">
              <template slot-scope="scope">
                <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle>
                </el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <div>
            <p style="font-size: 12px;">{{groups.cloning.additionStat}}</p>
            <p style="font-size: 12px;">{{groups.cloning.footerText}}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <pieChart v-if="!loading" :data="groups.cloning.data" :nameValue="'value'" :yValue="'percentage'"></pieChart>
        </el-col>
      </el-col>
      <el-col :span="12">

      </el-col>
    </el-row>

    <el-row style="margin: 10px 0px 0px 0px;">
      <h3>Summary Data</h3>
      <el-col :span="24">

        <table class="hiaTable">
          <thead>
            <tr>
              <th>Patient Number</th>
              <th>Medical Record Number</th>
              <th>Available At Coding?</th>
              <th>Available at Review?</th>
              <th>Missing Appropriate Order</th>
              <th>Incorrect Admit Date</th>
              <th>Record Appears to Have Cloning</th>
              <th>Cloning Caused Coding Problem</th>
            </tr>
          </thead>
          <tr v-for="(row, i) in tableData" :key="i">
            <td class="textAlignLeft">{{row.patientNumber}}</td>
            <td>{{row.mrNumber}}</td>
            <td>{{ row.dcSummAvailableCoding }}</td>
            <td>{{ row.dcSummAvailableReview }}</td>
            <td><i title="" :style="`color: ${row.missingAppropriateOrder ? `#13ce66;` : ``}`" :class="row.missingAppropriateOrder ? 'el-icon-check' : ''"></i></td>
            <td><i title="" :style="`color: ${row.incorrectAdmitDischDate ? `#13ce66;` : ``}`" :class="row.incorrectAdmitDischDate ? 'el-icon-check' : ''"></i></td>
            <td><i title="" :style="`color: ${row.recordAppearsToHaveCloning ? `#13ce66;` : ``}`" :class="row.recordAppearsToHaveCloning ? 'el-icon-check' : ''"></i></td>
            <td><i title="" :style="`color: ${row.cloningCausedCodingProblem ? `#13ce66;` : ``}`" :class="row.cloningCausedCodingProblem ? 'el-icon-check' : ''"></i></td>
          </tr>
          <tr class="strong emphasis" v-if="tableData.length > 0">
            <td class="textAlignLeft">Totals</td>
            <td>{{tableData.length}}</td>
          </tr>
          <tr v-if="tableData.length === 0">
            <td colspan="11">{{loading ? 'loading...' : 'No Data Found'}}</td>
          </tr>
        </table>

        <!-- <el-table :id="`IPDocAssessment`" class="tableNoWrap" :ref="`IPDocAssessment`" :data="tableData" empty-text="No Data Available" max-height="425" stripe :cell-class-name="cellClasses" :summary-method="getSummaries" show-summary row-key="drgTableId">
          <el-table-column prop="patientNumber" label="Patient Number" sortable>
            <template slot-scope="scope">
              <router-link v-bind:to="`/reviews/inpatient/${scope.row.drgTableId}`">{{scope.row.patientNumber}}
              </router-link>
            </template>
          </el-table-column>

          <el-table-column prop="mrNumber" label="Medical Record Number" sortable>
            <template slot-scope="scope">
              <div tag="mrNumber" :title="scope.row.mrNumber" v-html="highlightValue(scope.row.mrNumber, filterCriteria)"></div>
            </template>
          </el-table-column>

          <el-table-column prop="dcSummAvailableCoding" label="Available At Coding?" sortable>
            <template slot-scope="scope">
              <div tag="dcSummAvailableCoding" :title="scope.row.dcSummAvailableCoding" v-html="highlightValue(scope.row.dcSummAvailableCoding, filterCriteria)"></div>
            </template>
          </el-table-column>

          <el-table-column prop="dcSummAvailableReview" label="Available at Review?" sortable>
            <template slot-scope="scope">
              <div tag="dcSummAvailableReview" :title="scope.row.dcSummAvailableReview" v-html="highlightValue(scope.row.dcSummAvailableReview, filterCriteria)"></div>
            </template>
          </el-table-column>

          <el-table-column prop="missingAppropriateOrder" label="Missing Appropriate Order" sortable>
            <template slot-scope="scope">
              <i title="" :style="`color: ${scope.row.missingAppropriateOrder ? `#13ce66;` : ``}`" :class="scope.row.missingAppropriateOrder ? 'el-icon-check' : ''"></i>
            </template>
          </el-table-column>

          <el-table-column prop="incorrectAdmitDischDate" label="Incorrect Admit Date" sortable>
            <template slot-scope="scope">
              <i title="" :style="`color: ${scope.row.incorrectAdmitDischDate ? `#13ce66;` : ``}`" :class="scope.row.incorrectAdmitDischDate ? 'el-icon-check' : ''"></i>
            </template>
          </el-table-column>

          <el-table-column prop="recordAppearsToHaveCloning" label="Record Appears to Have Cloning" sortable>
            <template slot-scope="scope">
              <i title="" :style="`color: ${scope.row.recordAppearsToHaveCloning ? `#13ce66;` : ``}`" :class="scope.row.recordAppearsToHaveCloning ? 'el-icon-check' : ''"></i>
            </template>
          </el-table-column>

          <el-table-column prop="cloningCausedCodingProblem" label="Cloning Caused Coding Problem" sortable>
            <template slot-scope="scope">
              <i title="" :style="`color: ${scope.row.cloningCausedCodingProblem ? `#13ce66;` : ``}`" :class="scope.row.cloningCausedCodingProblem ? 'el-icon-check' : ''"></i>
            </template>
          </el-table-column>
        </el-table> -->
      </el-col>
    </el-row>

    <!-- <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList :header="detailsHeader" v-if="dialogVisible" :drgTableIds="subProRecordIds"></accountList>
    </el-dialog> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  // import accountList from './accoutList'
  import pieChart from '../highcharts/pieChart'

  export default {
    name: 'IP_DocAssessmentPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds'],
    components: {
      // accountList,
      pieChart
    },
    data: function () {
      return {
        tableData: [],
        noData: [],
        groups: {
          summaryAtTimeOfCoding: {
            title: null,
            footertext: null,
            additionStat: null,
            data: []
          },
          summaryAtTimeOfReview: {
            title: null,
            footertext: null,
            additionStat: null,
            data: []
          },
          admitDate: {
            title: null,
            footertext: null,
            additionStat: null,
            data: []
          },
          cloning: {
            title: null,
            footertext: null,
            additionStat: null,
            data: []
          },
          missingAppropriateOrder: {
            title: null,
            footertext: null,
            additionStat: null,
            data: []
          }
        },
        recordTotals: {},
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/ipDocAssessment`, payload)
          this.tableData = response.data.docAssessment
          this.groups = response.data.groups
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.value
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      getSummaries(param) {
        const sums = []
        sums[0] = 'Total'
        sums[1] = this.tableData ? this.tableData.length : null
        if (param.columns.find(f => f.property === 'percentage')) {
          sums[2] = this.formatPercentage(param.data.reduce((a, b) => a + b.percentage, 0))
        }
        return sums
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      codeTypesAndData() {
        const codeTypeList = [...new Set(this.tableData.map(x => x.codeDesc))]
        const codeTypesAndData = []
        for (let i = 0; i < codeTypeList.length; i++) {
          codeTypesAndData.push({
            codeDesc: codeTypeList[i],
            data: this.tableData.filter(x => x.codeDesc === codeTypeList[i])
          })
        }

        return codeTypesAndData
      }
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
.totalsTable {
  border-spacing: 0px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.totalsTable td {
  padding: 13px;
}
</style>
