<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-for="group in allData" :key="group.key" style="margin-top: 30px; margin-bottom:20px;">
      <h3>{{ group.key }}</h3>

      <table class="hiaTable" style="margin-top: 20px;">
        <thead>
          <tr>
            <th>HCC</th>
            <th>Description</th>
            <th>No Recommendations</th>
            <th>Additions</th>
            <th>Deletions</th>
            <th>Accuracy Rate</th>
          </tr>
        </thead>
        <tr v-for="item in group.data" :key="item.key">
          <td class="textAlignLeft">{{ item.code }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.numOfNoRec }}</td>
          <td>{{ item.numOfAdditions }}</td>
          <td>{{ item.numOfDeletions }}</td>
          <td>{{ formatPercentage(item.accuracyRate) }}</td>
        </tr>
        <tr class="strong emphasis">
          <td class="textAlignLeft">Total</td>
          <td class="textAlignLeft"></td>
          <td>{{ getSummaries(group.data)[1] }}</td>
          <td>{{ getSummaries(group.data)[2] }}</td>
          <td>{{ getSummaries(group.data)[3] }}</td>
          <td>{{ getSummaries(group.data)[4] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'

  export default {
    name: 'PRO_ByHccPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'codeTypes'],
    components: {},
    data: function () {
      return {
        allData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proByHcc`, payload)
          this.allData = response.data.data
        } catch (err) {
          this.allData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [{ property: 'numOfNoRec' }, { property: 'numOfNoRec' }, { property: 'numOfAdditions' }, { property: 'numOfDeletions' }, { property: 'hccCodes' }],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }
</style>