<template>
  <div>
    <div style="margin-top: 50px;" v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
    <div v-if="!loading">
      <div v-for="worksheet in worksheets" :key="worksheet.patientNumber" class="page-break" style="margin-bottom: 50px;">
        <h3>Pro Review Worksheet</h3>
        <ProWorksheet :worksheet="worksheet" :optionalParameters="optionalParameters"></ProWorksheet>
      </div>
      <div v-if="worksheets.length === 0">
        No worksheets found, there are no records with changes
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { format, parseISO } from 'date-fns'
  import ProWorksheet from '@/views/reviews/pro/worksheets/ProWorksheet.vue'
  import axios from '@/utilities/axios-global'

  export default {
    name: 'Worksheets',
    props: ['proRecordIds', 'codeTypes', 'optionalParameters'],
    data() {
      return {
        loading: true,
        format: format,
        parseISO: parseISO,
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups', 'clients']
      }
    },
    components: {
      ProWorksheet
    },
    methods: {
      async getWorksheets() {
        this.loading = true
        const ids = {
          ids: this.proRecordIds,
          codeTypes: this.codeTypes
        }
        if (this.providerReviewer()) {
          const response = await axios.post(this.baseApiUrl + '/reviews/pro/worksheets?changesOnly=true', ids)
          this.worksheets = response.data
        }

        if (!this.providerReviewer() && this.coder()) {
          const response = await axios.post(this.baseApiUrl + '/coder/pro/worksheets?changesOnly=true', ids)
          this.worksheets = response.data
        }

        this.loading = false
      }
    },
    mounted: async function () {
      await this.getWorksheets()
    },
    watch: {
      showDrgWorksheets: function () {
        this.getWorksheets()
      },
      showFyiWorksheets: function () {
        this.getWorksheets()
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    }
  }
</script>

<style scoped>
  @media print {
    .page-break {
      display: block;
      page-break-after: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>