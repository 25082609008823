<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-for="codeType in getCodeTypes()" style="margin-top: 30px; margin-bottom:20px;" :key="codeType">
      <h3>{{codeType}}</h3>

      <table class="hiaTable" style="margin-top: 20px;">
        <thead>
          <tr>
            <th> Description </th>
            <th> Total Records </th>
            <th> RVU Impact </th>
          </tr>
        </thead>
        <tr v-for="item in tableData.filter(x => x.codeDesc === codeType)" :key="item.description">
          <td class="textAlignLeft"> {{item.changeDesc}} </td>
          <td> {{item.numRecords}} </td>
          <td :class="`${item.rvuDiff < 0 ? 'negative' : ''}`"> {{item.rvuDiff}}</td>
        </tr>
        <tr class="strong emphasis">
          <td class="textAlignLeft">Total</td>
          <td>{{getSummaries(tableData.filter(x => x.codeDesc === codeType))[1]}}</td>
          <td :class="`${getSummaries(tableData.filter(x => x.codeDesc === codeType))[2] < 0 ? 'negative' : ''}`">{{getSummaries(tableData.filter(x => x.codeDesc ===
            codeType))[2]}}</td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'

  export default {
    name: 'PRO_ByProviderPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'codeTypes'],
    components: {
    },
    data: function () {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proByHeaderReason`, payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getCodeTypes() {
        const codeTypes = [...new Set(this.tableData.map(x => x.codeDesc))]
        return codeTypes
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'reason' },
            { property: 'numRecords' },
            { property: 'rvuDiff' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
.totalsTable {
  border-spacing: 0px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.totalsTable td {
  padding: 13px;
}
</style>
