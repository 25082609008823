<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-for="(group) in allData" :key="group.key" style="margin-top: 30px; margin-bottom:20px;">
      <h3>{{group.key}}</h3>

      <table class="hiaTable" style="margin-top: 20px;">
        <thead>
          <tr>
            <th> Reason </th>
            <th> Total Records </th>
            <th>Financial Impact</th>
          </tr>
        </thead>
        <tr v-for="item in group.data" :key="item.key">
          <td class="textAlignLeft"> {{item.description}} </td>
          <td> {{item.number}} </td>
          <td :class="`${item.amt < 0 ? 'negative' : ''}`"> {{item.amt}}</td>
        </tr>
        <tr class="strong emphasis">
          <td class="textAlignLeft">Total</td>
          <td>{{getSummaries(group.data)[1]}}</td>
          <td :class="`${getSummaries(group.data)[2] < 0 ? 'negative' : ''}`">{{getSummaries(group.data)[2]}}</td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'

  export default {
    name: 'OPByReasonPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds'],
    components: {
    },
    data: function () {
      return {
        allData: [],
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/opByAscReason`, payload)
          this.allData = response.data.data
          this.tableData = response.data.data
        } catch (err) {
          this.allData = []
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'description' },
            { property: 'number' },
            { property: 'amt' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
.totalsTable {
  border-spacing: 0px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.totalsTable td {
  padding: 13px;
}
</style>
