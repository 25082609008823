<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <v-server-table style="min-height: 120px;" ref="proReviewListGrid" id="proReviewListGrid" :columns="columns" :options="options" class="hiaTable"
      @loading="tableLoader = true" @loaded="tableLoader = false" v-loading="tableLoader" element-loading-spinner="atom-audit-loader">
      <!-- <template slot="selected" slot-scope="props">
        <input v-model="props.row.checked" @click="selectRecord(props.row, $event)" type="checkbox">
      </template> -->
      <template slot="projectName" slot-scope="props">
        <div>
          <router-link v-bind:to="`/projects/${props.row.projectId}`">{{props.row.projectName}}
          </router-link>
        </div>
      </template>
      <template slot="patientNumber" slot-scope="props">
        <div>
          <router-link v-bind:to="`/reviews/pro/${props.row.proRecordId}`">{{props.row.patientNumber}}
          </router-link>
        </div>
      </template>
      <template slot="dateOfService" slot-scope="props">
        <div>
          {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
        </div>
      </template>
      <template slot="completeDate" slot-scope="props">
        <div>
          {{ props.row.completeDate ? formatDate(props.row.completeDate) : '' }}
        </div>
      </template>
      <template slot="reviewDate" slot-scope="props">
        <div>
          {{ props.row.reviewDate ? formatDate(props.row.reviewDate) : '' }}
        </div>
      </template>

      <template slot="recordTags" slot-scope="props">
        <template v-if="props.row.recordTags">
          <div class="el-tag el-tag--mini el-tag--light" style="max-width: 100px; " v-for="tag in props.row.recordTags.split(',')" :key="tag">
            <div :title="tag" style="overflow: hidden; text-overflow: ellipsis;">
              {{ tag }}
            </div>
          </div>
        </template>
      </template>

    </v-server-table>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'inpatientListFilter',
    mixins: [dateHelper, hiaTable],
    data() {
      return {
        localInpatientData: {},
        filterCriteria: '',
        tableLoader: false,
        loading: false,
        vueTables2Query: {},
        columns: ['projectName', 'patientNumber', 'physicianName', 'numberEMCodes', 'numberCPTCodes', 'numberFindings', 'mrNumber', 'consultantNumber', 'recordTags', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'placeOfService', 'completeDate', 'status', 'numberDxCodes', 'reviewDate'],
        options: {
          requestFunction: (data) => {
            return this.requestData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          sortable: ['projectName', 'patientNumber', 'physicianName', 'numberEMCodes', 'numberCPTCodes', 'numberFindings', 'mrNumber', 'consultantNumber', 'recordTags', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'placeOfService', 'completeDate', 'status', 'numberDxCodes', 'reviewDate'],
          orderBy: {
            column: 'patientNumber',
            ascending: true
          },
          columnsClasses: {
            selected: 'selected',
            comments: 'customComments',
            delete: 'delete'
          },
          headings: {
            selected: 'selected',
            projectName: 'Project',
            patientNumber: 'Patient Number',
            physicianName: 'Provider',
            numberEMCodes: '# EM Codes',
            numberCPTCodes: '# CPT Codes',
            numberFindings: '# Findings',
            level2NumberFindings: '# Lvl 2 Findings',
            mrNumber: 'MR Number',
            consultantNumber: 'Auditor',
            payorName: 'Payor',
            coder: 'Coder',
            reasonForChange: 'Reason F Change',
            reviewLevel: 'Review Date',
            originalReviewer: 'Orig Reviewer',
            level2ReasonForChange: 'Lvl 2 Reason F Change',
            dateOfService: 'Date of Service',
            placeOfService: 'Place of Service',
            providerType: 'Provider Type',
            practiceName: 'Practice Name',
            patientName: 'Patient Name',
            completeDate: 'Complete Date',
            rebuttalCount: 'Rebuttal Count',
            rebuttalActiveCount: 'Active Rebuttals',
            rebuttalRespondedCount: 'Completed Rebuttals',
            status: 'Review Status',
            numberDxCodes: '# Dx Codes',
            reviewDate: 'Review Date',
            delete: 'delete'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        }
      }
    },
    methods: {
      ...mapActions('reports/', ['LOAD_PROREPORTDATA_FILTERED']),
      ...mapMutations('reports/', ['SET_REPORTS_PRO_VUETABLEDATA', 'SET_REPORTS_TYPE']),
      async requestData(data) {
        try {
          this.SET_REPORTS_PRO_VUETABLEDATA(data)
          const payload = {
            proReport: this.proReport
          }
          this.statsLoading = true
          await Promise.all([this.LOAD_PROREPORTDATA_FILTERED(payload)])
          this.statsLoading = false
          const proResults = {
            count: this.proReport.proData.paginatedCount,
            data: this.proReport.proData.proRecordListView
          }
          return proResults
        } catch (err) {
          console.log(err)
        }
      },
      refreshData() {
        this.$refs.reviewListGrid.getData()
      },
      showAddMoreColumns() {
        const popper = this.$refs.sortableColumns
        popper.referenceElm = this.$refs.sortableColumnsRef
        popper.doShow()
      }
    },
    watch: {},
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('reports/', ['proReport'])
    },
    created: function () {
      // this.localInpatientData = JSON.parse(JSON.stringify(this.reports.inpatientData))
    },
    components: {}
  }
</script>

<style scoped>
  >>>.VueTables__filters-row {
    display: none;
  }

  >>>.hiaTable>.table-responsive {
    overflow-x: auto;
  }
</style>