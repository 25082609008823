<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <!-- <h3>{{group.key}}</h3> -->
    <table class="hiaTable" style="margin-top: 20px;">
      <thead>
        <tr>
          <!-- <th> Change Type </th> -->
          <th> Patient Number </th>
          <th> MR Number </th>
          <th> Discharge Date </th>
          <th> Payor </th>
          <th> Orig. DRG </th>
          <th> Orig. Weight </th>
          <th> Orig. Reimb. </th>
          <th> Rec. DRG </th>
          <th> Rec. Weight </th>
          <th> Rec. Reimb. </th>
          <th> Wt. Diff. </th>
          <th> Fin. Impact </th>
        </tr>
      </thead>
      <tr v-for="(item, index) in allData" :key="index">
        <!-- <td class="textAlignLeft"> {{item.reimbursementChangeType}} </td> -->
        <td class="textAlignLeft"> {{item.patNum}} </td>
        <td class="textAlignLeft"> {{item.medRecNum}} </td>
        <td class="textAlignLeft"> {{format(parseISO(item.dschDate), 'M/d/yyyy')}} </td>
        <td class="textAlignLeft"> {{item.payorName}} </td>
        <td> {{item.orDRG}} </td>
        <td> {{item.orDRGWt}} </td>
        <td> {{moneyFormatter(item.originalReimb)}} </td>
        <td> {{item.neDRG}} </td>
        <td> {{item.neDRGWt}} </td>
        <td> {{moneyFormatter(item.newReimb)}} </td>
        <td>
          <div :class="`${item.weightDifference < 0 ? 'negative' : ''}` ">{{item.weightDifference}}</div>
        </td>
        <td>
          <div :class="`${item.financialImpact < 0 ? 'negative' : ''}` ">{{moneyFormatter(item.financialImpact)}}</div>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'IP_RebillLogPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds'],
    components: {
    },
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        allData: [],
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/inpatientRebillLog`, payload)
          this.allData = response.data
          this.tableData = response.data
        } catch (err) {
          this.allData = []
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            // this.$refs.dxTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'coder' },
            { property: 'totalRecords' },
            { property: 'totalCodes' },
            { property: 'cmCodes' },
            { property: 'emCodes' },
            { property: 'cptCodes' },
            { property: 'modCodes' },
            { property: 'rvuDiff' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>