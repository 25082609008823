<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <table class="hiaTable">
      <thead>
        <tr>
          <th>HCC</th>
          <th>Description</th>
          <th>No Recommendations</th>
          <th>Additions</th>
          <th>Deletions</th>
          <th>Accuracy Rate</th>
        </tr>
      </thead>
      <tr v-for="(row, i) in tableData" :key="i">
        <td class="textAlignLeft">{{ row.code }}</td>
        <td>{{ row.description }}</td>
        <td>{{ row.numOfNoRec }}</td>
        <td>{{ row.numOfAdditions }}</td>
        <td>{{ row.numOfDeletions }}</td>
        <td>{{ formatPercentage(row.accuracyRate) }}</td>
      </tr>
      <tr class="strong emphasis" v-if="tableData.length > 0">
        <td class="textAlignLeft">Total</td>
        <td class="textAlignLeft"></td>
        <td>{{ summary[1] }}</td>
        <td>{{ summary[2] }}</td>
        <td>{{ summary[3] }}</td>
        <td>{{ summary[4] }}</td>
      </tr>
      <tr v-if="tableData.length === 0">
        <td colspan="7" class="textAlignLeft">{{loading ? 'loading...' : 'No Dx Data Found'}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'

  export default {
    name: 'IP_HccSummaryV28Printable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds'],
    components: {},
    data: function () {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {}
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipByHccV28', payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      summary: function () {
        const param = {
          columns: [
            { property: 'numOfNoRec' },
            { property: 'numOfNoRec' },
            { property: 'numOfAdditions' },
            { property: 'numOfDeletions' },
            { property: 'hccCodes' }],
          data: this.tableData
        }
        return this.getSummary(param)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>