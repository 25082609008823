<template>
  <div id="proAuditReport" v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-if="!loading">
      <div class="options print-hidden report-header-row">
        <el-row>
          <el-col :span="22">
            <el-row>
              <el-col :span="6">
                <el-button type="text" @click="selectAll">Select All</el-button>
                <el-button type="text" @click="unselectAll">Unselect All</el-button>
              </el-col>
            </el-row>

            <el-row class="hidden-sm">
              <el-col :span="6" v-for="(chunk, i) in chunkDashboards(4)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label>
                </div>
              </el-col>
            </el-row>

            <el-row class="hidden-lg">
              <el-col :span="8" v-for="(chunk, i) in chunkDashboards(3)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2">
            <el-button icon="mdi mdi-file-download-outline" class="pull-right print-hidden hiaButton" round plain style="margin-top: 30px; margin-bottom: 0px; z-index: 100;"
              size="mini" @click="saveReport">Download </el-button>
          </el-col>
        </el-row>
        <el-row v-if="showWorksheets">
          <div style="display: inline-block; padding: 15px 10px 15px 10px; background-color: #f3f2f1a9; margin-top: 15px; margin-bottom: 10px; border-radius: 5px; ">
            <h5 style="margin-bottom: 8px; margin-top: 0px;">Optional Parameters</h5>
            <input type="checkbox" v-model="optionalParameters.hideWorksheetCoderResponses" />
            <label class="toc">Hide Worksheet Coder/Provider Responses</label>
          </div>
        </el-row>
      </div>
      <div class="mainReport" style="margin-top: 20px;">
        <TenantReportHeader></TenantReportHeader>
        <h1 contenteditable style="margin-top: 20px;" @input="setTitle($event)">{{ title ? `Pro Coding Audit Results for ${title}` : `Pro Coding Audit Results` }}<i
            style="font-size: 16px; margin-left: 5px; cursor: pointer; color: #b4bccc" class="mdi mdi-pencil print-hidden"></i></h1>
        <div v-if="codeTypesDesc !== ''">
          <h4>{{ codeTypesDesc }} Code Types</h4>
        </div>
        <div v-for="(dashboard, i) in dashboards.filter(f => getShowFlag(f.showFlag))" :key="dashboard.name">
          <div>
            <h2 :class="i > 0 ? 'page-break' : ''" :id="dashboard.scrollTo">{{ dashboard.name }}</h2>
            <component ref="parentComponent" :is="dashboard.component" :proRecordIds="proRecordIds" :codeTypes="codeTypes" :optionalParameters="optionalParameters">
            </component>
          </div>
        </div>
        <span class="watermark">Powered by Atom Audit</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import TenantReportHeader from '@/views/reports/printable/TenantReportHeader.vue'
  import PROBasicSummaryPrintable from './printable/PRO_BasicSummaryPrintable.vue'
  import PROCombinedFindingsPrintable from './printable/PRO_FindingSummaryCombinedPrintable.vue'
  import PRODxFindingsPrintable from './printable/PRO_FindingSummaryDXPrintable.vue'
  import PROCptFindingsPrintable from './printable/PRO_FindingSummaryCPTPrintable.vue'
  import PROEmFindingsPrintable from './printable/PRO_FindingSummaryEMPrintable.vue'
  import PROWorksheetFindingsPrintable from './printable/PRO_FindingSummaryWorksheetPrintable.vue'
  import PROModFindingsPrintable from './printable/PRO_FindingSummaryModPrintable.vue'
  import PROByCoderPrintable from './printable/PRO_ByCoderPrintable.vue'
  import PROByProjectPrintable from './printable/PRO_ByProjectPrintable.vue'
  import PROByServiceLinePrintable from './printable/PRO_ByServiceLinePrintable.vue'
  import PROByProviderPrintable from './printable/PRO_ByProviderPrintable.vue'
  import PROByPatientTypePrintable from './printable/PRO_ByPatientTypePrintable.vue'
  import PROByFacilityPrintable from './printable/PRO_ByFacilityPrintable.vue'
  import PROByRecordReasonPrintable from './printable/PRO_ByRecordReasonPrintable.vue'
  import PROByDxPrintable from './printable/PRO_ByDxPrintable.vue'
  import PROByHccPrintable from './printable/PRO_ByHCCPrintable.vue'
  import PROByHccV28Printable from './printable/PRO_ByHCCV28Printable.vue'
  import PROByCptPrintable from './printable/PRO_ByCptPrintable.vue'
  import PROByEMPrintable from './printable/PRO_ByEMPrintable.vue'
  import PROByModifierPrintable from './printable/PRO_ByModifierPrintable.vue'
  import PROWorksheetsPrintable from './printable/PRO_WorksheetsPrintable.vue'
  import PRORebillLogPrintable from './printable/PRO_RebillLogPrintable.vue'
  import PROByProviderTrendingPrintable from './printable/PRO_ByProviderTrendingPrintable.vue'
  import PROByCoderTrendingPrintable from './printable/PRO_ByCoderTrendingPrintable.vue'
  import PROChecklistSummaryPrintable from './printable/PRO_ChecklistSummaryPrintable.vue'

  export default {
    name: 'ProAuditReport',
    components: {
      TenantReportHeader,
      PROBasicSummaryPrintable,
      PROCombinedFindingsPrintable,
      PRODxFindingsPrintable,
      PROCptFindingsPrintable,
      PROEmFindingsPrintable,
      PROWorksheetFindingsPrintable,
      PROModFindingsPrintable,
      PROByCoderPrintable,
      PROByProjectPrintable,
      PROByProviderPrintable,
      PROByPatientTypePrintable,
      PROByFacilityPrintable,
      PROByRecordReasonPrintable,
      PROByDxPrintable,
      PROByHccPrintable,
      PROByHccV28Printable,
      PROByCptPrintable,
      PROByEMPrintable,
      PROByModifierPrintable,
      PROWorksheetsPrintable,
      PROByServiceLinePrintable,
      PRORebillLogPrintable,
      PROByProviderTrendingPrintable,
      PROByCoderTrendingPrintable,
      PROChecklistSummaryPrintable
    },
    props: [],
    data: function () {
      return {
        title: null,
        loading: false,
        width: window.innerWidth,
        proRecordIds: null,
        codeTypes: [],
        codeTypesDesc: '',
        optionalParameters: {
          hideWorksheetCoderResponses: false
        },
        showAccuracySummary: true,
        showWorksheetFindings: true,
        showCombinedFindings: true,
        showDxFindings: true,
        showCptFindings: true,
        showEmFindings: true,
        showModFindings: true,
        showByCoder: true,
        showByProject: true,
        showByProvider: true,
        showByPatientType: true,
        showByFacility: true,
        showByReason: true,
        showDx: true,
        showHcc: true,
        showHccV28: true,
        showEm: true,
        showCpt: true,
        showMod: true,
        showChecklist: true,
        showByServiceLine: true,
        showRebillLog: true,
        showWorksheets: false,
        showTrending: true,
        showCoderTrending: true,
        dashboards: [
          { name: 'Accuracy Summary', component: 'PROBasicSummaryPrintable', showFlag: 'showAccuracySummary', scrollTo: 'accuracySummary', reportSectionOnly: false },
          { name: 'Audit Findings Summary', component: 'PROWorksheetFindingsPrintable', showFlag: 'showWorksheetFindings', scrollTo: 'auditFindings', reportSectionOnly: false },
          { name: 'Combined Code Change Summary', component: 'PROCombinedFindingsPrintable', showFlag: 'showCombinedFindings', scrollTo: 'combinedFindings', reportSectionOnly: false },
          { name: 'Dx Change Summary', component: 'PRODxFindingsPrintable', showFlag: 'showDxFindings', scrollTo: 'dxFindings', reportSectionOnly: false },
          { name: 'CPT Change Summary', component: 'PROCptFindingsPrintable', showFlag: 'showCptFindings', scrollTo: 'cptFindings', reportSectionOnly: false },
          { name: 'E/M Change Summary', component: 'PROEmFindingsPrintable', showFlag: 'showEmFindings', scrollTo: 'emFindings', reportSectionOnly: false },
          { name: 'Modifier Change Summary', component: 'PROModFindingsPrintable', showFlag: 'showModFindings', scrollTo: 'modFindings', reportSectionOnly: false },
          { name: 'By Facility', component: 'PROByFacilityPrintable', showFlag: 'showByFacility', scrollTo: 'byFacility', reportSectionOnly: false },
          { name: 'By Project', component: 'PROByProjectPrintable', showFlag: 'showByProject', scrollTo: 'byProject', reportSectionOnly: false },
          { name: 'By Coder', component: 'PROByCoderPrintable', showFlag: 'showByCoder', scrollTo: 'byCoder', reportSectionOnly: false },
          { name: 'Coder Trending', component: 'PROByCoderTrendingPrintable', showFlag: 'showCoderTrending', scrollTo: 'proCoderTrending', reportSectionOnly: true },
          { name: 'By Provider', component: 'PROByProviderPrintable', showFlag: 'showByProvider', scrollTo: 'byProvider', reportSectionOnly: false },
          { name: 'Provider Trending', component: 'PROByProviderTrendingPrintable', showFlag: 'showTrending', scrollTo: 'proTrending', reportSectionOnly: true },
          { name: 'By Patient Type', component: 'PROByPatientTypePrintable', showFlag: 'showByPatientType', scrollTo: 'byPatientType', reportSectionOnly: false },
          { name: 'By Record Reason', component: 'PROByRecordReasonPrintable', showFlag: 'showByReason', scrollTo: 'byReason', reportSectionOnly: false },
          { name: 'By Service Line', component: 'PROByServiceLinePrintable', showFlag: 'showByServiceLine', scrollTo: 'byServiceLine', reportSectionOnly: false },
          { name: 'Pro Fee Schedule Rebill Log', component: 'PRORebillLogPrintable', showFlag: 'showRebillLog', scrollTo: 'rebillLog', reportSectionOnly: false },
          { name: 'By Dx', component: 'PROByDxPrintable', showFlag: 'showDx', scrollTo: 'byDx', reportSectionOnly: false },
          { name: 'By HCC V24', component: 'PROByHccPrintable', showFlag: 'showHcc', scrollTo: 'byHcc', reportSectionOnly: false },
          { name: 'By HCC V28', component: 'PROByHccV28Printable', showFlag: 'showHccV28', scrollTo: 'byHccV28', reportSectionOnly: false },
          { name: 'By CPT', component: 'PROByCptPrintable', showFlag: 'showCpt', scrollTo: 'byCpt', reportSectionOnly: false },
          { name: 'By E/M', component: 'PROByEMPrintable', showFlag: 'showEm', scrollTo: 'byEm', reportSectionOnly: false },
          { name: 'By Modifier', component: 'PROByModifierPrintable', showFlag: 'showMod', scrollTo: 'byModifier', reportSectionOnly: false },
          { name: 'Checklist Summary', component: 'PROChecklistSummaryPrintable', showFlag: 'showChecklist', scrollTo: 'checklistSummary', reportSectionOnly: false },
          { name: 'Worksheets', component: 'PROWorksheetsPrintable', showFlag: 'showWorksheets', scrollTo: 'worksheets', reportSectionOnly: false }
        ]
      }
    },
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      setTitle(evt) {
        window.document.title = evt.target.innerText
      },
      saveReport() {
        window.print()
      },
      scrollTo(id) {
        const target = document.getElementById(id)
        if (target) {
          window.scroll({
            top: target.offsetTop - 195,
            left: 0,
            behavior: 'smooth'
          })
        }
      },
      unselectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = false
        })
      },
      selectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = true
        })
      },
      chunkDashboards(totalChunks) {
        const baseChunkSize = parseInt(this.dashboards.length / totalChunks)
        const remainder = this.dashboards.length % totalChunks
        let offset = 0

        const chunkedResult = []

        for (let i = 1; i <= totalChunks; i++) {
          if (i <= remainder) {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset + 1
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
            offset++
          } else {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
          }
        }

        return chunkedResult
      },
      getShowFlag(showFlag) {
        return this[showFlag]
      },
      toggleShowFlag(showFlag) {
        this[showFlag] = !this[showFlag]
      }
    },
    created: async function () {
      this.loading = true
      const ids = window.localStorage.getItem('reportIds')
      const codeTypes = window.localStorage.getItem('codeTypes')
      const title = window.localStorage.getItem('reportTitle')
      const codeTypesDesc = window.localStorage.getItem('codeTypesDesc')
      const isReportSection = window.localStorage.getItem('isReportSection')
      const calcDashboards = this.dashboards

      if (isReportSection === 'false') {
        for (let i = 0; i <= this.dashboards.length - 1; i++) {
          if (this.dashboards[i].reportSectionOnly) {
            calcDashboards.splice(i, 1)
          }
        }

        this.dashboards = calcDashboards
      }

      let arrCodeTypes = []
      if (codeTypes !== '') {
        arrCodeTypes = codeTypes.split(',')
        arrCodeTypes.forEach(a => {
          a = parseInt(a)
        })
      }

      this.proRecordIds = ids
      this.codeTypes = arrCodeTypes
      this.title = title
      this.codeTypesDesc = codeTypesDesc
      window.document.title = this.title ? `Pro Coding Audit Results for ${this.title}` : 'Pro Coding Audit Results'
      await this.GET_TENANT_SETUP()
      this.loading = false
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    }
  }
</script>

<style scoped>
  .toc {
    font-size: 14px;
    margin: 8px;
  }

  .toc a {
    color: #606266;
  }

  .toc a:hover {
    color: #337ab7;
    text-decoration: underline;
  }

  .report-header-row {
    box-shadow: 0px 4px 4px -6px rgba(0, 0, 0, 0.8) !important;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 20px 0px 20px 0px;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
  }

  >>>.strong {
    font-weight: bold;
  }

  >>>.emphasis {
    font-style: italic;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .red-font {
    color: red;
  }

  >>>table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
  }

  >>>td,
  >>>th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
  }

  >>>thead {
    text-align: left !important;
    padding-top: 5px;
    /* background-color: #e1f5fe; */
  }

  >>>tr:nth-child(even) {
    background-color: #fafafa;
  }

  >>>.worksheet .demographics tr:nth-child(even) {
    background-color: unset;
  }

  >>>.worksheet .stripe tr:nth-child(even) {
    background-color: unset;
  }

  h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  h2 {
    margin: 50px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .options label {
    margin: 5px 5px 5px 5px;
  }

  .watermark {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    .hidden-lg {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .hidden-sm {
      display: none;
    }
  }

  @media print {
    .watermark {
      display: block;
      font-size: 8px;
      bottom: 0;
      position: fixed;
      right: 10px;
      font-style: italic;
    }

    .page-break {
      display: block;
      page-break-before: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>