<template>
  <!-- <div id="outpatientAuditReport" v-loading="loading" element-loading-spinner="atom-audit-loader" style="max-width: 1000px; margin: auto;"> -->
  <div id="outpatientAuditReport" v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-if="!loading">
      <div class="options print-hidden report-header-row">
        <el-row>
          <el-col :span="22">
            <el-row>
              <el-col :span="6">
                <el-button type="text" @click="selectAll">Select All</el-button>
                <el-button type="text" @click="unselectAll">Unselect All</el-button>
              </el-col>
            </el-row>

            <el-row class="hidden-sm">
              <el-col :span="6" v-for="(chunk, i) in chunkDashboards(4)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"
                    ><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label
                  >
                </div>
              </el-col>
            </el-row>

            <el-row class="hidden-lg">
              <el-col :span="8" v-for="(chunk, i) in chunkDashboards(3)" :key="i">
                <div v-for="dashboard in chunk" :key="dashboard.name">
                  <input type="checkbox" :checked="getShowFlag(dashboard.showFlag)" @change="toggleShowFlag(dashboard.showFlag)" />
                  <label class="toc"
                    ><a href="#" @click.prevent="scrollTo(dashboard.scrollTo)">{{ dashboard.name }}</a></label
                  >
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="2">
            <el-button icon="mdi mdi-file-download-outline" class="pull-right print-hidden hiaButton" round plain style="margin-top: 30px; margin-bottom: 0px; z-index: 100;" size="mini" @click="saveReport">Download </el-button>
          </el-col>
        </el-row>
        <el-row v-if="showWorksheets">
          <div style="display: inline-block; padding: 15px 10px 15px 10px; background-color: #f3f2f1a9; margin-top: 15px; margin-bottom: 10px; border-radius: 5px; ">
            <h5 style="margin-bottom: 8px; margin-top: 0px;">Optional Parameters</h5>
            <input type="checkbox" v-model="optionalParameters.hideWorksheetCoderResponses" />
            <label class="toc">Hide Worksheet Coder Responses</label>
          </div>
        </el-row>
      </div>
      <div class="mainReport" style="margin-top: 20px;">
        <TenantReportHeader></TenantReportHeader>
        <h1 contenteditable style="margin-top: 20px;" @input="setTitle($event)">{{ title ? `Outpatient Coding Audit Results for ${title}` : `Outpatient Coding Audit Results` }}<i style="font-size: 16px; margin-left: 5px; cursor: pointer; color: #b4bccc" class="mdi mdi-pencil print-hidden"></i></h1>
        <h4 v-if="codeTypesDesc !== ''">{{ codeTypesDesc }} Code Types</h4>

        <div v-for="(dashboard, i) in dashboards.filter(f => getShowFlag(f.showFlag))" :key="dashboard.name">
          <div>
            <h2 :class="i > 0 ? 'page-break' : ''" :id="dashboard.scrollTo">{{ dashboard.name }}</h2>

            <template v-if="dashboard.component !== 'IPWorksheetsPrintable'">
              <component ref="parentComponent" :is="dashboard.component" :ascTableIds="ascTableIds" :codeTypes="codeTypes" :optionalParameters="optionalParameters"> </component>
            </template>
          </div>
        </div>
        <span class="watermark">Powered by Atom Audit</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import TenantReportHeader from '@/views/reports/printable/TenantReportHeader.vue'
  import OPBasicSummaryPrintable from './printable/OP_BasicSummaryPrintable.vue'
  import OPByCoderPrintable from './printable/OP_ByCoderPrintable.vue'
  import OPByPatientTypePrintable from './printable/OP_ByPatientTypePrintable.vue'
  import OPByFacilityPrintable from './printable/OP_ByFacilityPrintable.vue'
  import OPByProjectPrintable from './printable/OP_ByProjectPrintable.vue'
  import OPByServiceLinePrintable from './printable/OP_ByServiceLinePrintable.vue'
  import OPByDxPrintable from './printable/OP_ByDxPrintable.vue'
  import OPByHccPrintable from './printable/OP_ByHCCPrintable.vue'
  import OPByHccV28Printable from './printable/OP_ByHCCPrintableV28.vue'
  import OPByPxPrintable from './printable/OP_ByPxPrintable.vue'
  import OPByCptPrintable from './printable/OP_ByCptPrintable.vue'
  import OPByModifierPrintable from './printable/OP_ByModifierPrintable.vue'
  import OPByReasonPrintable from './printable/OP_ByReasonPrintable.vue'
  import OPWorksheetsPrintable from './printable/OP_WorksheetsPrintable.vue'
  import OPTrending from './printable/OP_Trending.vue'

  import OPCombinedFindingsPrintable from './printable/OP_FindingSummaryCombinedPrintable.vue'
  import OPDxFindingsPrintable from './printable/OP_FindingSummaryDXPrintable.vue'
  import OPCptFindingsPrintable from './printable/OP_FindingSummaryCPTPrintable.vue'
  import OPWorksheetFindingsPrintable from './printable/OP_FindingSummaryWorksheetPrintable.vue'
  import OPModFindingsPrintable from './printable/OP_FindingSummaryModPrintable.vue'

  export default {
    name: 'OutpatientAuditReport',
    components: {
      TenantReportHeader,
      OPBasicSummaryPrintable,
      OPByCoderPrintable,
      OPByPatientTypePrintable,
      OPByFacilityPrintable,
      OPByProjectPrintable,
      OPByServiceLinePrintable,
      OPByDxPrintable,
      OPByHccPrintable,
      OPByHccV28Printable,
      OPByPxPrintable,
      OPByCptPrintable,
      OPByModifierPrintable,
      OPByReasonPrintable,
      OPWorksheetsPrintable,
      OPTrending,
      OPCombinedFindingsPrintable,
      OPDxFindingsPrintable,
      OPCptFindingsPrintable,
      OPWorksheetFindingsPrintable,
      OPModFindingsPrintable
    },
    props: [],
    data: function() {
      return {
        title: null,
        loading: false,
        ascTableIds: null,
        codeTypes: [],
        codeTypesDesc: '',
        optionalParameters: {
          hideWorksheetCoderResponses: false
        },
        showByCoder: true,
        showByProvider: true,
        showByPatientType: true,
        showByFacility: true,
        showByReason: true,
        showByProject: true,
        showByServiceLine: true,
        showDx: true,
        showHcc: true,
        showHccV28: true,
        showEm: true,
        showCpt: true,
        showMod: true,
        showEmLevel: true,
        showWorksheets: false,
        showPx: true,
        showApcReason: true,
        showTrending: true,
        showAccSumm: true,
        showWorksheetFindings: true,
        showCombinedFindings: true,
        showDxFindings: true,
        showCptFindings: true,
        showModFindings: true,
        dashboards: [
          { name: 'Accuracy Summary', component: 'OPBasicSummaryPrintable', showFlag: 'showAccSumm', scrollTo: 'accSumm', reportSectionOnly: false },
          { name: 'Audit Findings Summary', component: 'OPWorksheetFindingsPrintable', showFlag: 'showWorksheetFindings', scrollTo: 'auditFindings', reportSectionOnly: false },
          { name: 'Combined Code Change Summary', component: 'OPCombinedFindingsPrintable', showFlag: 'showCombinedFindings', scrollTo: 'combinedFindings', reportSectionOnly: false },
          { name: 'Dx Change Summary', component: 'OPDxFindingsPrintable', showFlag: 'showDxFindings', scrollTo: 'dxFindings', reportSectionOnly: false },
          { name: 'CPT Change Summary', component: 'OPCptFindingsPrintable', showFlag: 'showCptFindings', scrollTo: 'cptFindings', reportSectionOnly: false },
          { name: 'Modifier Change Summary', component: 'OPModFindingsPrintable', showFlag: 'showModFindings', scrollTo: 'modFindings', reportSectionOnly: false },
          { name: 'By Coder', component: 'OPByCoderPrintable', showFlag: 'showByCoder', scrollTo: 'byCoder', reportSectionOnly: false },
          { name: 'OP Trending', component: 'OPTrending', showFlag: 'showTrending', scrollTo: 'ipTrending', reportSectionOnly: true },
          { name: 'By Patient Type', component: 'OPByPatientTypePrintable', showFlag: 'showByPatientType', scrollTo: 'byPatientType', reportSectionOnly: false },
          { name: 'By Facility', component: 'OPByFacilityPrintable', showFlag: 'showByFacility', scrollTo: 'byFacility', reportSectionOnly: false },
          { name: 'By Project', component: 'OPByProjectPrintable', showFlag: 'showByProject', scrollTo: 'byProject', reportSectionOnly: false },
          { name: 'By Service Line', component: 'OPByServiceLinePrintable', showFlag: 'showByServiceLine', scrollTo: 'byServiceLine', reportSectionOnly: false },
          { name: 'By APC Reason', component: 'OPByReasonPrintable', showFlag: 'showApcReason', scrollTo: 'byMDC', reportSectionOnly: false },
          { name: 'Dx Summary', component: 'OPByDxPrintable', showFlag: 'showDx', scrollTo: 'byDx', reportSectionOnly: false },
          { name: 'HCC V24 Summary', component: 'OPByHccPrintable', showFlag: 'showHcc', scrollTo: 'byHCC', reportSectionOnly: false },
          { name: 'HCC V28 Summary', component: 'OPByHccV28Printable', showFlag: 'showHccV28', scrollTo: 'byHCCV28', reportSectionOnly: false },
          { name: 'Px Summary', component: 'OPByPxPrintable', showFlag: 'showPx', scrollTo: 'byPx', reportSectionOnly: false },
          { name: 'CPT Summary', component: 'OPByCptPrintable', showFlag: 'showCpt', scrollTo: 'byHCC', reportSectionOnly: false },
          { name: 'Modifier Summary', component: 'OPByModifierPrintable', showFlag: 'showMod', scrollTo: 'byPx', reportSectionOnly: false },
          { name: 'Worksheets', component: 'OPWorksheetsPrintable', showFlag: 'showWorksheets', scrollTo: 'drgChangeWorksheets', reportSectionOnly: false }
        ]
      }
    },
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      setTitle(evt) {
        window.document.title = evt.target.innerText
      },
      saveReport() {
        window.print()
      },
      scrollTo(id) {
        const target = document.getElementById(id)
        if (target) {
          window.scroll({
            top: target.offsetTop - 175,
            left: 0,
            behavior: 'smooth'
          })
        }
      },
      unselectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = false
        })
      },
      selectAll() {
        this.dashboards.forEach(x => {
          this[x.showFlag] = true
        })
      },
      chunkDashboards(totalChunks) {
        const baseChunkSize = parseInt(this.dashboards.length / totalChunks)
        const remainder = this.dashboards.length % totalChunks
        let offset = 0

        const chunkedResult = []

        for (let i = 1; i <= totalChunks; i++) {
          if (i <= remainder) {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset + 1
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
            offset++
          } else {
            const chunkStart = (i - 1) * baseChunkSize + offset
            const chunkEnd = i * baseChunkSize + offset
            chunkedResult.push(this.dashboards.slice(chunkStart, chunkEnd))
          }
        }

        return chunkedResult
      },
      getShowFlag(showFlag) {
        return this[showFlag]
      },
      toggleShowFlag(showFlag) {
        this[showFlag] = !this[showFlag]
      }
    },
    created: async function() {
      this.loading = true
      const ids = window.localStorage.getItem('reportIds')
      const title = window.localStorage.getItem('reportTitle')
      const codeTypes = window.localStorage.getItem('codeTypes')
      const codeTypesDesc = window.localStorage.getItem('codeTypesDesc')
      const isReportSection = window.localStorage.getItem('isReportSection')
      const calcDashboards = this.dashboards

      if (isReportSection === 'false') {
        for (let i = 0; i <= this.dashboards.length - 1; i++) {
          if (this.dashboards[i].reportSectionOnly) {
            calcDashboards.splice(i, 1)
          }
        }
      }

      let arrCodeTypes = []
      if (codeTypes !== '') {
        arrCodeTypes = codeTypes.split(',')
        arrCodeTypes.forEach(a => {
          a = parseInt(a)
        })
      }

      this.ascTableIds = ids
      this.title = title
      this.codeTypes = arrCodeTypes
      this.codeTypesDesc = codeTypesDesc
      window.document.title = this.title ? `Outpatient Coding Audit Results for ${this.title}` : 'Outpatient Coding Audit Results'
      await this.GET_TENANT_SETUP()
      this.loading = false
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    }
  }
</script>

<style scoped>
  .toc {
    font-size: 14px;
    margin: 8px;
  }

  .toc a {
    color: #606266;
  }

  .toc a:hover {
    color: #337ab7;
    text-decoration: underline;
  }

  .report-header-row {
    box-shadow: 0px 4px 4px -6px rgba(0, 0, 0, 0.8) !important;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 20px 0px 20px 0px;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
  }

  >>> .strong {
    font-weight: bold;
  }

  >>> .emphasis {
    font-style: italic;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .red-font {
    color: red;
  }

  >>> table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
  }

  >>> td,
  >>> th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
  }

  >>> thead {
    text-align: left !important;
    padding-top: 5px;
    /* background-color: #e1f5fe; */
  }

  >>> tr:nth-child(even) {
    background-color: #fafafa;
  }

  >>> .worksheet .demographics tr:nth-child(even) {
    background-color: unset;
  }

  >>> .worksheet .stripe tr:nth-child(even) {
    background-color: unset;
  }

  h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  h2 {
    margin: 50px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .options label {
    margin: 5px 5px 5px 5px;
  }

  .watermark {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    .hidden-lg {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .hidden-sm {
      display: none;
    }
  }

  @media print {
    .watermark {
      display: block;
      font-size: 8px;
      bottom: 0;
      position: fixed;
      right: 10px;
      font-style: italic;
    }

    .page-break {
      display: block;
      page-break-before: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>
