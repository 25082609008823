<template>
  <div class="table-wrapper">
    <h2 id="proSummaryTitle" v-if="title"> {{ title }} </h2>
    <table class="hiaTable">
      <thead>
        <tr>
          <th style="width: 190px;"> </th>
          <th> Total Codes </th>
          <th> ICD-10 CM Codes </th>
          <th> EM Codes </th>
          <th> CPT Codes </th>
          <th> Modifier Codes </th>
        </tr>
      </thead>
      <tr>
        <td class="strong textAlignLeft"> Total Reviewed </td>
        <td> {{item.totalCodesReviewed}} </td>
        <td> {{item.dxTotalReviewed}} </td>
        <td> {{item.emTotalReviewed}} </td>
        <td> {{item.cptTotalReviewed}} </td>
        <td> {{item.modTotalReviewed}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> Total Recommendations </td>
        <td> {{item.totalCodesRecommended}} </td>
        <td> {{item.dxTotalRec}} </td>
        <td> {{item.emTotalRec}} </td>
        <td> {{item.cptTotalRec}} </td>
        <td> {{item.modTotalRec}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> No Recommendations </td>
        <td> {{item.totalCodesNoRec}} </td>
        <td> {{item.dxNoRec}} </td>
        <td> {{item.emNoRec}} </td>
        <td> {{item.cptNoRec}} </td>
        <td> {{item.modNoRec}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> Additions </td>
        <td> {{item.totalCodesAdditions}} </td>
        <td>{{item.dxAdditions}} </td>
        <td>{{item.emAdditions}} </td>
        <td>{{item.cptAdditions}} </td>
        <td>{{item.modAdditions}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> Deletions </td>
        <td> {{item.totalCodesDeletions}} </td>
        <td> {{item.dxDeletions}} </td>
        <td> {{item.emDeletions}} </td>
        <td> {{item.cptDeletions}} </td>
        <td> {{item.modDeletions}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> Revisions </td>
        <td> {{item.totalCodesRev}} </td>
        <td> {{item.dxRevisions}} </td>
        <td> {{item.emRevisions}} </td>
        <td> {{item.cptRevisions}} </td>
        <td> {{item.modRevisions}} </td>
      </tr>

      <tr>
        <td class="strong textAlignLeft"> Accuracy Rate </td>
        <td> {{ !item.totalCodesReviewed ? 'N/A' : formatPercentage(item.totalCodes) }} </td>
        <td> {{ !item.dxTotalReviewed ? 'N/A' : formatPercentage(item.cmCodes) }} </td>
        <td> {{ !item.emTotalReviewed ? 'N/A' : formatPercentage(item.emCodes) }} </td>
        <td> {{ !item.cptTotalReviewed ? 'N/A' : formatPercentage(item.cptCodes) }} </td>
        <td> {{ !item.modTotalReviewed ? 'N/A' : formatPercentage(item.modCodes) }} </td>
      </tr>
    </table>

  </div>
</template>

<script>
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  export default {
    name: 'IPBasicSummary',
    props: ['item', 'title'],
    mixins: [money, dashboardHelper],
    data: function () {
      return {
      }
    },
    methods: {}
  }
</script>

<style scoped>
  .hiaTable thead th {
    text-align: center;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>
