<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <IPSummaryPrintable v-for="item in tableData" :key="item.patTypeDesc" :item="item"></IPSummaryPrintable>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'

  import dashboardHelper from '@/mixins/dashboardHelper'
  import IPSummaryPrintable from './IP_SummaryPrintable'
  export default {
    name: 'IP_BasicSummaryPrintable',
    mixins: [hiaFilters, dashboardHelper],
    components: {
      IPSummaryPrintable
    },
    props: ['drgTableIds'],
    data: function () {
      return {
        tableData: [{}],
        filterCriteria: '',
        loading: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipBasicStat', payload)
          this.tableData = response.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    }
  }
</script>

<style scoped>
.strong {
  font-weight: bold;
}

.separator {
  height: 15px;
  background-color: lightslategray;
}

.red-font {
  color: red;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  table-layout: fixed;
}

td,
th {
  border: 2px solid #b4bccc;
  margin: 0px;
  padding: 4px;
  text-align: center;
  font-size: 90%;
}
</style>
