<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Auditor Metrics</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Reports</el-breadcrumb-item>
          <el-breadcrumb-item>Auditor Metrics</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div>
      <el-card>
        <el-tabs class="auditorDashboardTabs" v-model="mainActiveTab" @tab-click="mainActiveTabClick">
          <el-tab-pane label="Auditor Status" name="status" :disabled="false">
            <auditorStatusDashboards></auditorStatusDashboards>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
  // import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapState } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import auditorStatusDashboards from '@/views/reports/auditorStatusDashboards'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'auditorMetrics',
    mixins: [dateHelper, hiaTable, HiaAuthorization],
    data() {
      return {
        defaultDateRange: [],
        loading: false,
        mainActiveTab: 'status'
      }
    },
    methods: {
      // ...mapActions('reports/reportQueries/', ['GET_REPORTQUERIES', 'GET_REPORTQUERIES_USERS_LIST']),
      // ...mapMutations('reports/', ['SET_REPORTS_DEFAULTDATERANGE', 'SET_REPORTS_DEFAULTCOMPLETED', 'SET_REPORTS_OUTPATIENTGROUPERS']),
      async initialize() {
        this.setMainActiveTab()
      },
      mainActiveTabClick(tab, event) {

      },
      setMainActiveTab() {
        this.mainActiveTab = 'status'
      }
    },
    watch: {},
    computed: {
      ...mapState('user/', ['user', 'currentTenantUserId', 'baseApiUrl'])
    },
    created: function () {
      this.initialize()
    },
    components: {
      auditorStatusDashboards
    }
  }
</script>

<style scoped>
>>> .auditorDashboardTabs .el-tabs__item {
  font-size: 18px;
}

>>> .dataTabs .el-tabs__item {
  font-size: 14px;
}

>>> .filterIcon {
  float: left;
  font-size: 22px;
  padding-right: 10px;
}
</style>
