<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <table class="hiaTable">
      <thead>
        <tr>
          <th>Project</th>
          <th>Tags</th>
          <th>Start Date</th>
          <th>Total Records</th>
          <th>Total DRGs</th>
          <th>Total DRGs w/o Query</th>
          <th>Total Codes</th>
          <th>ICD-10 CM Codes</th>
          <th>ICD-10 PCS Codes</th>
          <th>Patient Status Codes</th>
          <th>Point of Origin Codes</th>
          <th>POA Indicators</th>
          <th>Reimb Diff</th>
        </tr>
      </thead>
      <tr v-for="(row, i) in tableData" :key="i">
        <td class="textAlignLeft">{{row.projectName}}</td>
        <td class="textAlignLeft">
          <div v-if="row.tags">
            <div class="printableTag" v-for="tag in row.tags.split(',')" :key="tag">
              <div class="">{{ tag }}</div>
            </div>
          </div>
        </td>
        <td class="textAlignLeft">{{row.startDate ? format(parseISO(row.startDate), 'MM/dd/yyyy') : ''}}</td>
        <td>{{row.totalRecords}}</td>
        <td>{{ formatPercentage(row.totalDRGs) }}</td>
        <td>{{ formatPercentage(row.totalDRGNoQuery) }}</td>
        <td>{{ formatPercentage(row.totalCodes) }}</td>
        <td>{{ formatPercentage(row.cmCodes) }}</td>
        <td>{{ formatPercentage(row.pcsCodes) }}</td>
        <td>{{ formatPercentage(row.patStatCodes) }}</td>
        <td>{{ formatPercentage(row.pooCodes) }}</td>
        <td>{{ formatPercentage(row.poaCodes) }}</td>
        <td :class="`${row.reimbDiff < 0 ? 'negative' : ''}` ">{{ moneyFormatter(row.reimbDiff) }}</td>
      </tr>
      <tr class="strong emphasis" v-if="tableData.length > 0">
        <td class="textAlignLeft">Totals</td>
        <td></td>
        <td></td>
        <td>{{summary[1]}}</td>
        <td>{{summary[2]}}</td>
        <td>{{summary[3]}}</td>
        <td>{{summary[4]}}</td>
        <td>{{summary[5]}}</td>
        <td>{{summary[6]}}</td>
        <td>{{summary[7]}}</td>
        <td>{{summary[8]}}</td>
        <td>{{summary[9]}}</td>
        <td :class="`${summary[10] < 0 ? 'negative' : ''}` ">{{summary[10]}}</td>
      </tr>
      <tr v-if="tableData.length === 0">
        <td colspan="11">{{loading ? 'loading...' : 'No Project Data Found'}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'IP_ByProjectPrintable',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds'],
    components: {},
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        tableData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {}
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipByProject', payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      summary: function () {
        const param = {
          columns: [
            { property: 'cliName' },
            { property: 'totalRecords' },
            { property: 'totalDRGs' },
            { property: 'totalDRGNoQuery' },
            { property: 'totalCodes' },
            { property: 'cmCodes' },
            { property: 'pcsCodes' },
            { property: 'patStatCodes' },
            { property: 'pooCodes' },
            { property: 'poaCodes' },
            { property: 'reimbDiff' }
          ],
          data: this.tableData
        }
        return this.getSummary(param)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }

  .printableTag {
    overflow-wrap: break-word;
  }

  .printableTag:not(:first-child) {
    margin-top: 10px;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>