<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <template v-if="!loading">
      <OPSummaryPrintable style="margin-top: 50px;" v-for="item in tableData" :title="getTitle(item)" :key="getTitle(item)" :item="item"></OPSummaryPrintable>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'

  import dashboardHelper from '@/mixins/dashboardHelper'
  import OPSummaryPrintable from './OP_SummaryPrintable'

  export default {
    name: 'OP_BasicSummaryPrintable',
    mixins: [hiaFilters, dashboardHelper],
    components: {
      OPSummaryPrintable
    },
    props: ['ascTableIds', 'codeTypes'],
    data: function () {
      return {
        tableData: [{}],
        filterCriteria: '',
        loading: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opBasicStat', payload)
          this.tableData = response.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      getTitle(item) {
        return item.patTypeDesc + ' - ' + item.codeDesc
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl'])
    },
    mounted: async function () {
      await this.getData()
    }
  }
</script>

<style scoped>
.strong {
  font-weight: bold;
}

.separator {
  height: 15px;
  background-color: lightslategray;
}

.red-font {
  color: red;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  table-layout: fixed;
}

td,
th {
  border: 2px solid #b4bccc;
  margin: 0px;
  padding: 4px;
  text-align: center;
  font-size: 90%;
}
</style>
