<template>
  <div :id="`${this._uid}_lineChart`">
  </div>
</template>

<script>
  // import DetailsPopOver from './DetailsPopOver'

  export default {
    name: 'lineChart',
    components: {

    },
    props: ['categories', 'series', 'xTitle', 'yTitle'],
    data: function () {
      return {
        highCharts: null,
        chartTitleStyle: {
          fontSize: '5px'
        },
        dataLabelsStyle: {
          color: 'black'
        }
      }
    },
    methods: {
      getOneToOneSeries(stats) {
        let data = []
        if (stats) {
          data = stats.map(m => {
            return {
              name: m[this.nameValue] === '<Not Specified>' ? '&lt;Not Specified&gt;' : m[this.nameValue],
              y: m[this.yValue],
              // y: 25.0,
              color: m.color
            }
          })
        }
        return data
      },
      renderOneToOneBarChart() {
        // const stats = this.reviewerStatistics.timeSheetsByConsultant
        // const drilldownStats = this.reviewerStatistics.timeSheetsByConsultantDrilldown
        // const [series, colors] = []
        // const series = this.getOneToOneSeries(this.data)
        // const categories = series.map(m => m.name)
        this.highCharts.chart(`${this._uid}_lineChart`, {
          chart: {
            type: 'spline',
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0
            // width: 700
          },
          title: {
            // text: 'My Timesheet Tasks',
            text: null
            // style: this.chartTitleStyle
          },
          tooltip: {
            formatter: function () {
              let s = `<span style="font-size: 10px;">${this.x}</span>`
              let total = 0
              this.points.forEach((point) => {
                s += `<br /><span style="color:${point.color}">●</span> ${point.series.name}: <b>${point.y}</b>`
                total += point.y
              })

              s += `<br/><span style="color: transparent;">●</span> Total: <b>${total}</b>`

              return s
            },
            // pointFormat: '<span style="color:{point.color}">●</span> {point.y}'
            shared: true,
            crosshairs: true
          },
          xAxis: {
            min: 0,
            categories: this.categories,
            title: {
              text: this.xTitle
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: this.yTitle
              // align: 'high'
            },
            labels: {
              overflow: 'justify'
            }
          },
          legend: {
            enabled: true,
            reversed: false,
            verticalAlign: 'top'
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: true
              },
              enableMouseTracking: false
            },
            series: {
              cursor: 'pointer',
              // className: 'popup-on-click',
              marker: {
                lineWidth: 1
              }
            }
          },
          credits: {
            enabled: false
          },
          series: this.series
        })
      }
    },
    created: function () {

    },
    mounted: function () {
      import(/* webpackChunkName: "highcharts" */'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })
    },
    computed: {
    },
    watch: {
      categories() {
        this.renderOneToOneBarChart()
      },
      highCharts() {
        if (this.highCharts) {
          this.renderOneToOneBarChart()
        }
      }
    }
  }
</script>

<style scoped>
>>> .highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

>>> .highcharts-root {
  width: 100% !important;
  height: 100% !important;
}
</style>
