<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div v-for="codeType in checklistsAndData" style="margin-top: 30px; margin-bottom:20px;" :key="codeType.codeDesc" class="codetype-wrapper">
      <h3>{{ codeType.codeDesc }}</h3>
      <el-row v-for="(checklist, index) in codeType.data" :key="index" class="checklist-wrapper" :gutter="20">
        <el-col :span="16">
          <el-col :span="24">
            <div>
              <table class="hiaTable">
                <thead>
                  <tr>
                    <th>Checklist</th>
                    <th style="padding-right: 13px;">Records</th>
                    <th style="padding-right: 13px;">All Criteria Met</th>
                    <th style="padding-right: 13px;">Accuracy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="textAlignLeft">{{ checklist.checklistName }}</td>
                    <td class="textAlignRight">{{ checklist.totalRecords }}</td>
                    <td class="textAlignRight">{{ checklist.allCriteriaMet }}</td>
                    <td class="textAlignRight">{{ formatPercentage(checklist.accuracy) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>

          <el-col :span="24">
            <div style="margin-left: 20px; margin-top: 20px;">
              <table class="hiaTable" style="margin-top: 20px;">
                <thead>
                  <tr>
                    <th style="width: 40%"> Question </th>
                    <th> Yes </th>
                    <th> No </th>
                    <th> N/A </th>
                    <th> Not Answered </th>
                  </tr>
                </thead>
                <tr v-for="(item, index) in checklist.checklistSummary" :key="index">
                  <td class="textAlignLeft" style="width: 40%"> {{item.question}} </td>
                  <td> {{item.yes}} </td>
                  <td> {{item.no}} </td>
                  <td> {{item.na}} </td>
                  <td> {{item.notAnswered}} </td>
                </tr>
                <tr class="strong emphasis">
                  <td class="textAlignLeft">Total</td>
                  <td>{{getSummaries(checklist.checklistSummary)[1]}}</td>
                  <td>{{getSummaries(checklist.checklistSummary)[2]}}</td>
                  <td>{{getSummaries(checklist.checklistSummary)[3]}}</td>
                  <td>{{getSummaries(checklist.checklistSummary)[4]}}</td>
                </tr>
              </table>
            </div>

          </el-col>
        </el-col>

        <el-col :span="8" style="text-align: center; margin-top: 28px;">
          <div :id="`barchart${checklist.checklistId}${checklist.codeDesc}`"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import csvGenerator from '@/mixins/csvGenerator'
  import dateHelper from '@/mixins/date-helper'

  export default {
    name: 'PRO_FindingSummaryCPT',
    mixins: [hiaFilters, money, dashboardHelper, csvGenerator, dateHelper],
    props: ['proRecordIds', 'codeTypes'],
    components: {
    },
    data: function () {
      return {
        highCharts: null,
        checklistData: [],
        checklistTotals: {},
        filterCriteria: '',
        loading: false,
        loadingAccounts: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: '',
        accountDetails: [],
        tableOptions: {
          orderBy: {
            column: 'name',
            ascending: true
          },
          filterByColumn: true,
          headings: {
            patientNumber: 'Patient Number',
            payName: 'Payor',
            dateOfService: 'Date of Service',
            reasonForChange: 'Reason for Change',
            providerName: 'Provider'
          },
          columnsClasses: {
            name: 'smallColumn',
            description: 'wideColumn'
          },
          listColumns: {
            answer: [{ id: 'Yes', text: 'Yes' },
            { id: 'No', text: 'No' },
            { id: 'N/A', text: 'N/A' },
            { id: 'Not Answered', text: 'Not Answered' }]
          },
          perPage: 20,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proChecklistSummary`, payload)
          this.checklistData = response.data
          await this.$nextTick()
          this.renderCharts()
        } catch (err) {
          this.checklistData = []
        } finally {
          this.loading = false
        }
      },
      renderCharts() {
        for (let i = 0; i < this.checklistData.length; i++) {
          const data = this.checklistData[i].checklistSummary
          // Extract questions and values for each category
          const questions = data.map(item => item.question)
          const yesData = data.map(item => item.yes)
          const noData = data.map(item => item.no)
          const naData = data.map(item => item.na)
          const notAnsweredData = data.map(item => item.notAnswered)

          const htmlId = `barchart${this.checklistData[i].checklistId}${this.checklistData[i].codeDesc}`

          const barHeight = 80
          const chartHeight = barHeight * questions.length

          this.highCharts.chart(htmlId, {
            chart: {
              type: 'bar',
              height: chartHeight > barHeight ? chartHeight : barHeight * 3
            },
            title: {
              text: this.checklistData[i].checklistName,
              style: {
                fontSize: '16px', // Font size,
                fontFamily: 'Roboto, sans-serif'
              }
            },
            xAxis: {
              categories: questions
            },
            yAxis: {
              min: 0
            },
            legend: {
              reversed: true
            },
            plotOptions: {
              bar: {
                cursor: 'pointer'
              },
              series: {
                // grouping: true, // Make bars grouped side by side
                stacking: 'normal',
                dataLabels: {
                  enabled: false,
                  formatter: function () {
                    if (this.y !== 0) {
                      return this.y
                    }
                  }
                }
              }
            },
            series: [
              { name: 'Not Answered', data: notAnsweredData, color: '#9E9E9E' },
              { name: 'N/A', data: naData, color: '#f90089' },
              { name: 'No', data: noData, color: '#f9d25d' },
              { name: 'Yes', data: yesData, color: '#7cbe5f' }
            ]
          })
        }
      },
      getSummaries(data) {
        const param = {
          columns: [
            { property: 'question' },
            { property: 'yes' },
            { property: 'no' },
            { property: 'na' },
            { property: 'notAnswered' }
          ],
          data: data
        }
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'question') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading']),
      checklistsAndData() {
        const checklistList = [...new Set(this.checklistData.map(x => x.codeDesc))]
        const checklistsAndData = []
        for (let i = 0; i < checklistList.length; i++) {
          checklistsAndData.push({
            codeDesc: checklistList[i],
            data: this.checklistData.filter(x => x.codeDesc === checklistList[i])
          })
        }

        return checklistsAndData
      }
    },
    mounted: async function () {
      import(/* webpackChunkName: "highcharts" */'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
        this.getData()
      })
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  >>>.highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-credits {
    display: none;
  }

  .hiddenVisibility {
    visibility: hidden;
  }

  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }

  .checklist-wrapper:not(:first-child) {
    margin-top: 30px !important;
  }

  .codetype-wrapper:not(:first-child) {
    margin-top: 60px !important;
  }

  .totalsTable th:not(:first-child),
  .totalsTable td:not(:first-child) {
    text-align: right;
    margin-right: 5px;
  }

  .subTable>>>th:not(:first-child),
  .subTable>>>td:not(:first-child) {
    text-align: right;
    margin-right: 5px;
  }
</style>