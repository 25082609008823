<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Filtered Reports</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Reports</el-breadcrumb-item>
          <el-breadcrumb-item>Filtered Reports</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div>
      <el-card>
        <el-tabs class="reportTabs" v-model="mainActiveTab" @tab-click="mainActiveTabClick">
          <el-tab-pane label="Inpatient" name="ip" :disabled="!(coder() || inpatientReviewer())">
            <ipReportSection ref="ipReportSection" @activateQuery="activateQuery"></ipReportSection>
          </el-tab-pane>
          <el-tab-pane label="Outpatient" name="op" :disabled="!(coder() || outpatientReviewer())">
            <opReportSection ref="opReportSection" @activateQuery="activateQuery"></opReportSection>
          </el-tab-pane>
          <el-tab-pane label="Pro" name="pro" :disabled="!(coder() || providerReviewer())">
            <proReportSection ref="proReportSection" @activateQuery="activateQuery"></proReportSection>
          </el-tab-pane>
          <el-tab-pane label="Ambulatory" name="asc" :disabled="!(coder() || ambulatoryReviewer())">
            <ascReportSection ref="ascReportSection" @activateQuery="activateQuery">
            </ascReportSection>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import ipReportSection from '@/views/reports/ipReportSection'
  import opReportSection from '@/views/reports/opReportSection'
  import proReportSection from '@/views/reports/proReportSection'
  import ascReportSection from '@/views/reports/ascReportSection'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'reportLanding',
    mixins: [dateHelper, hiaTable, HiaAuthorization],
    data() {
      return {
        defaultDateRange: [],
        loading: false,
        mainActiveTab: 'ip'
      }
    },
    methods: {
      ...mapActions('reports/reportQueries/', ['GET_REPORTQUERIES', 'GET_REPORTQUERIES_USERS_LIST']),
      ...mapMutations('reports/', ['SET_REPORTS_DEFAULTDATERANGE', 'SET_REPORTS_DEFAULTCOMPLETED', 'SET_REPORTS_OUTPATIENTGROUPERS']),
      async initialize() {
        this.setMainActiveTab()
        const end = new Date()
        const start = new Date(new Date().getFullYear(), 0, 1)
        this.defaultDateRange = [this.formatDate(start), this.formatDate(end)]
        this.SET_REPORTS_DEFAULTDATERANGE(this.defaultDateRange)
        this.SET_REPORTS_DEFAULTCOMPLETED()

        this.SET_REPORTS_OUTPATIENTGROUPERS()

        await this.GET_REPORTQUERIES_USERS_LIST()
        await this.GET_REPORTQUERIES()
      },
      mainActiveTabClick(tab, event) {
        // this.resetAll()
        if (tab === 'ip') {
          this.$refs.ipReportSection.ipActiveTab = 'ip'
        }
        if (tab === 'op') {
          this.$refs.opReportSection.opActiveTab = 'op'
        }
        if (tab === 'pro') {
          this.$refs.proReportSection.proActiveTab = 'pro'
        }
        if (tab === 'asc') {
          this.$refs.ascReportSection.ascActiveTab = 'asc'
        }
      },
      activateQuery(query) {
        switch (query.type) {
          case 'ip':
            this.$refs.ipReportSection.activateQuery(query)
            this.mainActiveTab = 'ip'
            break
          case 'op':
            this.$refs.opReportSection.activateQuery(query)
            this.mainActiveTab = 'op'
            break
          case 'pro':
            this.$refs.proReportSection.activateQuery(query)
            this.mainActiveTab = 'pro'
            break
          case 'asc':
            this.$refs.ascReportSection.activateQuery(query)
            this.mainActiveTab = 'asc'
            break
        }
      },
      setMainActiveTab() {
        if (this.ambulatoryReviewer()) {
          this.mainActiveTab = 'asc'
        }
        if (this.providerReviewer()) {
          this.mainActiveTab = 'pro'
        }
        if (this.outpatientReviewer()) {
          this.mainActiveTab = 'op'
        }
        if (this.inpatientReviewer()) {
          this.mainActiveTab = 'ip'
        }
      }
    },
    watch: {},
    computed: {
      ...mapState('user/', ['user', 'currentTenantUserId', 'baseApiUrl'])
    },
    created: function () {
      this.initialize()
    },
    components: {
      ipReportSection,
      opReportSection,
      proReportSection,
      ascReportSection
    }
  }
</script>

<style scoped>
  .queryRow {
    margin: 0px 0px 10px 0px;
  }

  .filterRow {
    margin: 5px 0px 0px 0px;
    min-height: 25px;
  }

  .divider div {
    font-size: 12px;
  }

  .dataTabs {
    margin: 0px 0px 0px 0px;
  }

  .filterBlock {
    font-size: 11px;
    float: left;
    margin: 5px 0px 0px 3px;
  }

  .filterBlock button {
    padding: 3px 15px;
    width: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filterBlock button.removeFilter {
    width: auto;
  }

  .filterBlock .removeFilter {
    padding: 3px 5px;
  }

  .saveQuery {
    float: left;
    margin: 0px 0px 0px 5px;
  }

  .saveQuery button {
    padding: 3px 15px;
  }

  .saveQuery button span {
    font-size: 11px;
  }

  .hiaRangePicker {
    width: 300px;
  }

  .activeFilter {
    background: #ecf5ff;
  }

  .filterMenu.el-menu--collapse {
    width: 50px;
  }

  .filterMenu .el-menu-item,
  .filterMenu>>>.el-submenu__title {
    padding: 0px 0px 0px 0px;
    padding-left: 0px !important;
  }

  .filterMenu>>>.el-menu--inline .el-menu-item {
    padding-left: 10px !important;
  }

  >>>.filterActionButtons {}

  >>>.filterActionButtons button i {
    font-size: 12px;
  }

  >>>.filterActionButtons .el-popover__reference-wrapper {
    line-height: 0px;
    float: left;
    position: relative;
  }

  .queryTree>>>.el-tree-node .el-tree-node__content:hover {
    background-color: transparent;
  }

  .queryTree>>>.el-tree-node__children .el-tree-node__content:hover {
    background-color: #f5f7fa !important;
    border-radius: 20px;
  }

  .queryTree>>>.el-tree-node__content {
    height: unset;
  }

  .queryTree>>>.el-tree-node.is-current>.el-tree-node__content {
    background-color: transparent;
  }

  .queryTree>>>.el-tree-node__children>.is-current>.el-tree-node__content {
    background-color: #f5f7fa !important;
    border-radius: 20px;
  }

  /* beta styles */
  >>>.reportCard .el-card__body {
    padding: 5px;
  }

  >>>.queryCommandButton {
    width: 33%;
  }

  >>>.queryCommandButton .el-button--mini {
    width: 70%;
  }

  >>>.queryCommandButton .el-dropdown__caret-button {
    width: 30%;
  }

  >>>.queryActions {
    float: right;
  }

  >>>.queryActions button {
    padding: 7px 7px 0px 7px;
  }

  >>>.queryActions button span {
    font-size: 18px;
  }

  .reportDivider {
    margin: 14px 0;
  }

  .reportDivider .el-divider__text {
    font-size: 11px;
    padding: 0 10px;
    font-weight: 400;
  }

  .dataRow {
    margin: 10px 0px 0px 0px;
  }

  >>>.reportTabs .el-tabs__item {
    font-size: 18px;
  }

  >>>.dataTabs .el-tabs__item {
    font-size: 14px;
  }

  >>>.numberTag {
    background-color: rgba(255, 199, 46, 0.2);
    border-color: rgba(255, 199, 46, 0.2);
    color: rgba(255, 199, 46, 1);
  }

  >>>.textTag {
    background-color: rgba(0, 86, 149, 0.2);
    border-color: rgba(0, 86, 149, 0.2);
    color: #1b3c5f;
  }

  >>>.dateTag {
    background-color: rgba(124, 190, 95, 0.2);
    border-color: rgba(124, 190, 95, 0.2);
    color: #1b3c5f;
  }

  >>>.tagTag {
    background-color: rgba(249, 0, 137, 0.2);
    border-color: rgba(249, 0, 137, 0.2);
    color: #1b3c5f;
  }

  >>>.tagIcon {
    padding: 0px 3px 0px 0px;
    margin: 2px 0px 0px 0px;
    float: left;
  }

  >>>.greenIcon {
    color: rgba(124, 190, 95, 1);
  }

  >>>.magentaIcon {
    color: rgba(249, 0, 137, 1);
  }

  >>>.goldIcon {
    color: rgba(87, 204, 244, 1);
  }

  >>>.blueIcon {
    color: rgba(0, 86, 149, 1);
  }

  >>>.tagSpan {
    font-size: 11px;
    line-height: 11px;
  }

  >>>.filterIcon {
    float: left;
    font-size: 22px;
    padding-right: 10px;
  }

  >>>.filter_text .el-button {
    background: rgba(249, 0, 137, 0.6);
    color: #ffffff;
  }

  >>>.filter_text .el-button--primary:first-child {
    border-right: 0px solid rgba(249, 0, 137, 1);
    border-left: 1px solid rgba(249, 0, 137, 1);
    border-top: 1px solid rgba(249, 0, 137, 1);
    border-bottom: 1px solid rgba(249, 0, 137, 1);
  }

  >>>.filter_text .el-button--primary:last-child {
    border-right: 1px solid rgba(249, 0, 137, 1);
    border-left: 0px solid rgba(249, 0, 137, 1);
    border-top: 1px solid rgba(249, 0, 137, 1);
    border-bottom: 1px solid rgba(249, 0, 137, 1);
    margin-left: 1px;
  }

  >>>.filter_text .el-button--primary:last-child:hover {
    border-right: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_text .el-button--primary:first-child:hover {
    border-left: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_date .el-button {
    background: rgba(124, 190, 95, 0.7);
    color: #ffffff;
  }

  >>>.filter_date .el-button--primary:first-child {
    border-right: 0px solid rgba(124, 190, 95, 1);
    border-left: 1px solid rgba(124, 190, 95, 1);
    border-top: 1px solid rgba(124, 190, 95, 1);
    border-bottom: 1px solid rgba(124, 190, 95, 1);
  }

  >>>.filter_date .el-button--primary:last-child {
    border-right: 1px solid rgba(124, 190, 95, 1);
    border-left: 0px solid rgba(124, 190, 95, 1);
    border-top: 1px solid rgba(124, 190, 95, 1);
    border-bottom: 1px solid rgba(124, 190, 95, 1);
    margin-left: 1px;
  }

  >>>.filter_date .el-button--primary:last-child:hover {
    border-right: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_date .el-button--primary:first-child:hover {
    border-left: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_number .el-button {
    background: rgba(0, 86, 149, 0.7);
    color: #ffffff;
  }

  >>>.filter_number .el-button--primary:first-child {
    border-right: 0px solid rgba(0, 86, 149, 1);
    border-left: 1px solid rgba(0, 86, 149, 1);
    border-top: 1px solid rgba(0, 86, 149, 1);
    border-bottom: 1px solid rgba(0, 86, 149, 1);
  }

  >>>.filter_number .el-button--primary:last-child {
    border-right: 1px solid rgba(0, 86, 149, 1);
    border-left: 0px solid rgba(0, 86, 149, 1);
    border-top: 1px solid rgba(0, 86, 149, 1);
    border-bottom: 1px solid rgba(0, 86, 149, 1);
    margin-left: 1px;
  }

  >>>.filter_number .el-button--primary:last-child:hover {
    border-right: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_number .el-button--primary:first-child:hover {
    border-left: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_tag .el-button {
    background: rgba(87, 204, 244, 0.7);
    color: #ffffff;
  }

  >>>.filter_tag .el-button--primary:first-child {
    border-right: 0px solid rgba(87, 204, 244, 1);
    border-left: 1px solid rgba(87, 204, 244, 1);
    border-top: 1px solid rgba(87, 204, 244, 1);
    border-bottom: 1px solid rgba(87, 204, 244, 1);
  }

  >>>.filter_tag .el-button--primary:last-child {
    border-right: 1px solid rgba(87, 204, 244, 1);
    border-left: 0px solid rgba(87, 204, 244, 1);
    border-top: 1px solid rgba(87, 204, 244, 1);
    border-bottom: 1px solid rgba(87, 204, 244, 1);
    margin-left: 1px;
  }

  >>>.filter_tag .el-button--primary:last-child:hover {
    border-right: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }

  >>>.filter_tag .el-button--primary:first-child:hover {
    border-left: 1px solid #005695;
    border-top: 1px solid #005695;
    border-bottom: 1px solid #005695;
  }
</style>

<style>
  .sortableColumnsForm {
    /* height: 300px; */
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: transparent;
} */
</style>